import React, { useState, useEffect } from 'react';
import {
  onUserDataRead,
  onMessageRead,
  onStatsBedsRead,
  onDollarDataRead,
} from '../../actions';
import { useSelector, useDispatch } from 'react-redux';
import './Home.scss';
import { Col, Row, Badge, Button, message, Statistic, Card } from 'antd';
import '../../scss/primaryButton.scss';
import '../../scss/secondButton.scss';
import '../../scss/linkButton.scss';
import MessageList from '../../components/Message/MessageList';
import Spin from '../../components/Spin';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import Modal from '../../components/Modal';
import moment from 'moment';

export default function Home() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();
  const { email, name, lastname } = useSelector((store) => store.userData.user);
  const { loading } = useSelector((store) => store.userData);
  const { messages } = useSelector((store) => store.messageData);
  const { dollarOficial, dollarBlue, lastUpdate } = useSelector(
    (store) => store.dollarData
  );

  useEffect(() => {
    if (!email) {
      dispatch(onUserDataRead());
    }
    dispatch(onDollarDataRead());

    dispatch(onMessageRead());
  }, [email]);

  if (loading) {
    return <Spin />;
  }

  const showMessages = () => {
    setIsVisibleModal(true);
    setModalTitle('Mensajes');
    setModalContent(
      <MessageList messages={messages} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  return (
    <div className="home">
      <div className="sm-responsive">
        <h6>{`¡ Bienvenido ${name} ${lastname} !`}</h6>
        <div className="messages">
          <Badge count={messages?.length}>
            <Button
              type="link"
              onClick={() => {
                messages?.length > 0
                  ? showMessages()
                  : message.info('No hay mensajes');
              }}
            >
              <MessageOutlined />
            </Button>
          </Badge>
        </div>
      </div>
      <div>
        <Row>
          <Col xs={24} md={12}>
            <Row gutter={16}>
              <Col span={4} />
              <Col span={8}>
                <Card>
                  <Statistic
                    title={'Dolar Oficial'}
                    value={dollarOficial}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<span>$</span>}
                    suffix={
                      <Statistic
                        style={{ marginLeft: '5px' }}
                        value={lastUpdate}
                        valueStyle={{ fontSize: '10px' }}
                        prefix="ult. act."
                      />
                    }
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Dolar Blue"
                    value={dollarBlue}
                    valueStyle={{ color: '#032777' }}
                    prefix={<span>$</span>}
                    suffix={
                      <Statistic
                        style={{ marginLeft: '5px' }}
                        value={lastUpdate}
                        valueStyle={{ fontSize: '10px' }}
                        prefix="ult. act."
                      />
                    }
                  />
                </Card>
              </Col>
              <Col span={4} />
            </Row>
          </Col>
          <Col sm={12} className="md-responsive">
            <div>
              {messages && messages.length > 0 && (
                <Col span={24}>
                  <MessageList messages={messages} />
                </Col>
              )}
              <h3>{`¡ Bienvenido ${name} ${lastname} !`}</h3>

              <div className="home-logo">
                <img
                  src={`https://storage.googleapis.com/bs-proyecto1.appspot.com/viarco/logo.png`}
                  alt="Logo"
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'90%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
