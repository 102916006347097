import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { onClientCreate, onClientUpdate } from '../../actions';
export default function FormClient(props) {
  const { setIsVisibleModal, client } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.resetFields();
  }, [client]);

  const onFinish = (values) => {
    form.resetFields();
    if (!client) {
      dispatch(onClientCreate(values, setIsVisibleModal));
    } else {
      dispatch(onClientUpdate(client._id, values, setIsVisibleModal));
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="nameClient"
              initialValue={client ? client.name : ''}
              rules={[{ required: true, message: 'Debe cargar un nombre' }]}
            >
              <Input placeholder="Nombre comitente/cliente(*)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="type"
              initialValue={client && client.type}
              rules={[
                { required: true, message: 'Debe seleccionar el tipo de obra' },
              ]}
            >
              <Select placeholder="Seleccione un tipo de obra.. (*)">
                <Select.Option value="Desarrollo">Desarrollo</Select.Option>
                <Select.Option value="Privada">Privada</Select.Option>
                <Select.Option value="Publica">Publica</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="cuit" initialValue={client ? client.cuit : ''}>
              <Input placeholder="CUIT" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="invoice"
              initialValue={client ? client.invoice : ''}
            >
              <Input placeholder="Factura" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="mail" initialValue={client ? client.mail : ''}>
              <Input placeholder="E-Mail" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
