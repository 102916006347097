import React, { useEffect, useState } from 'react';
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  InputNumber,
  Divider,
  message,
  Input,
  DatePicker,
} from 'antd';
import moment from 'moment';
import '../../../scss/form.scss';
import Modal from '../../Modal';
import { useSelector, useDispatch } from 'react-redux';
import {
  onProvidersRead,
  onProviderValueChange,
  onMaterialCreate,
  onProductsRead,
  onProductValueChange,
} from '../../../actions';
import ProviderForm from '../../Provider/ProviderForm';
import ProductForm from '../ProductForm';
import PermissionsAsigner from '../../PermissionsAsigner';
import { ROLES } from '../../../utils/constants';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { addOrderApi } from '../../../api/order';
import { getAccessTokenApi } from '../../../api/auth';
import { priorities } from '../../../utils/constants';

export default function MaterialForm({
  setIsVisibleModalForm,
  work,
  provider,
  setReload,
}) {
  const dispatch = useDispatch();
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const { providers } = useSelector((store) => store.providerData);

  const { products } = useSelector((store) => store.productData);
  const [form] = Form.useForm();

  useEffect(() => {
    !provider && dispatch(onProvidersRead());
    dispatch(onProductsRead());
  }, []);

  const onFinish = (values) => {
    values.date = provider ? values.date : moment().toISOString();
    values.work = work;
    let token = getAccessTokenApi();

    if (provider) {
      dispatch(
        onMaterialCreate({
          materialData: values,
          setIsVisibleModal: setIsVisibleModalForm,
        })
      );
    } else {
      addOrderApi(token, values)
        .then((response) => {
          if (response?.code !== 200) {
            message.error(response.message);
          } else {
            message.success(response.message);
            form.resetFields();
            setReload(true);
            setIsVisibleModalForm(false);
          }
        })
        .catch(() => {
          message.error('Error del servidor, intente mas tarde.');
        });
    }
  };

  const addProvider = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo proovedor');
  };

  const addProduct = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo producto');
  };

  return (
    <div>
      <Form name="form" form={form} className="form" onFinish={onFinish}>
        {provider ? (
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="date"
                initialValue={moment()}
                rules={[{ required: true, message: 'Debe cargar la fecha' }]}
                labelCol={{ span: 24 }}
                label="Fecha de compra"
              >
                <DatePicker
                  style={{ width: '100%' }}
                  placeholder="Fecha de alta (*)"
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="provider"
                rules={[
                  { required: true, message: 'Debe elegir un proovedor' },
                ]}
                labelCol={{ span: 24 }}
                label="Proovedor"
                initialValue={provider !== true && provider}
              >
                <Select
                  placeholder="Seleccione un proovedor.. (*)"
                  showSearch
                  optionFilterProp="children"
                  disabled={provider !== true}
                >
                  <Select.Option disabled>
                    <Button type="link" onClick={addProvider}>
                      + Nuevo proovedor
                    </Button>
                  </Select.Option>
                  {providers.map((provider, index) => (
                    <Select.Option key={index} value={provider._id}>
                      {provider.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="invoice"
                initialValue=""
                labelCol={{ span: 24 }}
                label="Nro de factura"
              >
                <Input placeholder="Nro de Factura (*)" />
              </Form.Item>
            </Col>
          </Row>
        ) : (
          <Row gutter={[24, 24]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="priority"
                label="Prioridad"
                labelCol={{ span: 24 }}
                initialValue="Baja"
              >
                <Select showSearch placeholder="Prioridad">
                  {priorities.map((priority, index) => (
                    <Select.Option key={index} value={priority.name}>
                      {priority.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={16}>
              <Form.Item
                name="description"
                label="Descripción"
                labelCol={{ span: 24 }}
              >
                <Input.TextArea
                  placeholder="Observacion del pedido.."
                  autoSize={{ minRows: 1, maxRows: 4 }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Divider>Datos Materiales</Divider>
        <Form.List name="materials">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row gutter={24} key={index}>
                    <Col xs={23} md={10} lg={11}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'material']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir un material',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un material.. (*)"
                          optionFilterProp="children"
                        >
                          {products.map((product, index) => (
                            <Select.Option key={index} value={product._id}>
                              {product.category} - {product.name} (
                              {product.unit})
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={23} md={5} lg={3}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe cargar la cantidad',
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="Cantidad (*)"
                          // min={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={23} md={8} lg={9}>
                      <Form.Item {...field} name={[field.name, 'observation']}>
                        <Input.TextArea
                          placeholder="Observacion del material.."
                          autoSize={{ minRows: 1, maxRows: 4 }}
                        />
                      </Form.Item>
                    </Col>

                    {fields.length > 1 ? (
                      <Col span={1} style={{ paddingLeft: '0' }}>
                        <Button
                          type="link"
                          size="large"
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          <MinusCircleOutlined />
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                ))}
                <Form.Item style={{ textAlign: 'right' }}>
                  {fields.length < 20 ? (
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        add();
                      }}
                      size="large"
                    >
                      <PlusOutlined />
                    </Button>
                  ) : null}
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item>
          <Button className="button-submit" type="primary" htmlType="submit">
            {provider ? 'Guardar compra' : ' Enviar pedido'}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
      >
        {modalTitle === 'Cargando nuevo proovedor' ? (
          <ProviderForm setIsVisibleModal={setIsVisibleModal} />
        ) : (
          <ProductForm setIsVisibleModal={setIsVisibleModal} />
        )}
      </Modal>
    </div>
  );
}
