import {
  ON_WORK_CREATE,
  ON_WORK_FORM_OPEN,
  ON_WORK_FORM_SUBMIT,
  ON_WORK_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = { name: '', direction: '', loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_WORK_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_WORK_FORM_OPEN:
      return { INITIAL_STATE };
    case ON_WORK_FORM_SUBMIT:
      return { ...state, loading: true };
    case ON_WORK_CREATE:
      return { INITIAL_STATE, loading: false };
    default:
      return state;
  }
};
