import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Logo from '../../../assets/img/logo-black.png';
import './ViewMovement.scss';

export default function ViewMovement(props) {
  const { id } = props.match.params;
  const movements = useSelector((store) => store.movementList.movements);

  var createPdf = (html) => {};

  var movement = {};
  movements.map((i) => {
    if (i._id == id) {
      movement = i;
    }
  });

  return (
    <table
      cellspacing="1"
      cellpadding="3"
      border="1px"
      bgcolor="#fff"
      className="view-movement-table"
    >
      <div className="view-movement">
        <div className="view-movement__title">
          <h1>Movimiento de herramientas</h1>
          <img src={Logo} alt="logo" />
          <h1>Fecha:{moment(movement.date).format('DD/MM/YYYY')}</h1>
        </div>
        <h1>Destino:{movement.work}</h1>
        <h1>Solicitante:{movement.user}</h1>
        <h1>Responsable: </h1>
        <h1>Detalle:</h1>
        <table border={1} style={{ width: '100%' }}>
          <tr style={{ textAlign: 'center' }}>
            <th style={{ width: '10%' }}>Cantidad</th>
            <th style={{ width: '55%' }}>Descripcion</th>
            <th tyle={{ width: '20%' }}>Origen</th>
            <th style={{ width: '5%' }}></th>
          </tr>
          {movement.tools.map((tool) =>
            tool.quantity > 0 ? (
              <tr>
                <td style={{ textAlign: 'center', fontSize: '12px' }}>
                  {tool.quantity}
                </td>
                <td style={{ paddingLeft: 10, fontSize: '12px' }}>
                  {tool.name} {tool.mark ? `(marca: ${tool.mark})` : null}
                </td>
                <td style={{ paddingLeft: 10, fontSize: '12px' }}>
                  {tool.work}
                </td>
                <td></td>
              </tr>
            ) : null
          )}
        </table>
        <div className="view-movement__footer">
          <h1>Recibe conforme: ___________________________</h1>
          <h1 style={{ marginLeft: '10px' }}>
            Aclaracion: ________________________________
          </h1>
        </div>
      </div>
    </table>
  );
}
