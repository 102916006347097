import React, { useState } from 'react';
import { List, Button, Input } from 'antd';

import { useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';
import { Link } from 'react-router-dom';

import './ListMovements.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function ListMovements() {
  const movements = useSelector((store) => store.movementList.movements);
  const [search, setSearch] = useState('');

  const movementFilters = movements.filter(
    (movement) =>
      movement.work
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase().trim()) ||
      movement.user
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase().trim()) ||
      moment(movement.date)
        .format('MMMM')
        .includes(search.toLowerCase().trim()) ||
      moment(movement.date).format('YYYY').includes(search.toLowerCase().trim())
  );

  return (
    <div>
      <Input.Search
        placeholder="Buscar movimientos..."
        style={{ left: 0, margin: '0 10px 0 0' }}
        onSearch={(value) => setSearch(value)}
        allowClear
      />
      <List
        className="movements"
        itemLayout="horizontal"
        dataSource={search ? movementFilters : movements}
        renderItem={(movement) => <Movement movement={movement} />}
      />
    </div>
  );
}

function Movement(props) {
  const { movement } = props;
  const day = moment(movement.date).format('DD');
  const month = moment(movement.date).format('MMMM');
  const year = moment(movement.date).format('YYYY');

  return (
    <List.Item
      className="movement"
      actions={[
        <Link to={`download-movement/${movement._id}`}>
          <Button type="primary">
            <InfoCircleOutlined />
          </Button>
        </Link>,
      ]}
    >
      <div className="movement__date">
        <span style={{ marginRight: 2 }}>{day}</span>
        <span style={{ marginRight: 2 }}>{month}</span>
        <span>{year}</span>
      </div>
      <List.Item.Meta
        title={`Movimiento de herramientas hacia: ${movement.work}`}
        description={`El movimiento fue realizado por: ${movement.user}`}
      />
    </List.Item>
  );
}
