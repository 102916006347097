import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import { getOrders } from '../../api/order';
import { Button, List, Tag, Tooltip, message } from 'antd';
import { getAccessTokenApi } from '../../api/auth';
import Spin from '../../components/Spin';
import moment from 'moment';
import { priorities, statusCustomize } from '../../utils/constants';
import {
  CheckOutlined,
  InfoCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import '../../scss/list.scss';

export default function Orders(props) {
  const { id } = props.match.params;
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let token = getAccessTokenApi();
    setLoading(true);
    getOrders(token, id)
      .then((response) => {
        if (response?.code !== 200) {
          message.error(response?.message);
        } else {
          setOrders(response?.orders);
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
    setLoading(false);
  }, []);

  if (loading) return <Spin />;

  return (
    <div>
      <PageHeader title="Pedidos" />
      <List
        className="list"
        dataSource={orders}
        renderItem={(order) => <Order order={order} />}
        pagination={10}
        locale={{ emptyText: 'No hay pedidos' }}
      />
    </div>
  );
}

function Order({ order }) {
  let priority = priorities.find((p) => p.name === order.priority);

  return (
    <List.Item
      actions={[
        order?.statusSend && !order.closed && (
          <Link
            to={{
              pathname: `/view-order-detail/${order._id}`,
              hash: '#reception',
            }}
          >
            <Tooltip title="Gestionar recepción">
              <Button type="link">
                <SendOutlined />
              </Button>
            </Tooltip>
          </Link>
        ),
        <Link
          to={{
            pathname: `/view-order-detail/${order._id}`,
            hash: order.closed ? '#viewRecived' : '#view',
          }}
        >
          <Tooltip title="Ver pedido">
            <Button type="link">
              <InfoCircleOutlined />
            </Button>
          </Tooltip>
        </Link>,
      ]}
    >
      <div className="list__date">
        <span style={{ marginRight: 2 }}>
          {moment(order.date).format('DD')}
        </span>
        <span style={{ marginRight: 2 }}>
          {moment(order.date).format('MMMM')}
        </span>
        <span>{moment(order.date).format('YYYY')}</span>
      </div>
      <List.Item.Meta
        title={
          <span>
            Nro de pedido: {order.numberOrder} -{' '}
            <span>Prioridad: {priority.name}</span>
          </span>
        }
        description={
          <div>
            <span>
              <Tag
                icon={statusCustomize[order.status]?.icon}
                color={statusCustomize[order.status]?.color}
              >
                {order.status}{' '}
                {order.userAdmin &&
                  `- ${order?.userAdmin?.name} 
                ${order?.userAdmin?.lastname}`}
              </Tag>
            </span>
            {order?.statusSend && (
              <span>
                <Tag
                  icon={statusCustomize[order.statusSend]?.icon}
                  color={statusCustomize[order.statusSend]?.color}
                >
                  {order.statusSend}{' '}
                  {order.userReception &&
                    order.statusSend !== 'Pendiente envio' &&
                    `- ${order?.userReception?.name} 
                ${order?.userReception?.lastname}`}
                </Tag>
              </span>
            )}
            <br />
            <span>
              Pedido por: {order?.user.name} {order?.user.lastname}{' '}
            </span>{' '}
            <br />
            {order?.description && (
              <span>Descripción: {order?.description} </span>
            )}
          </div>
        }
      />
    </List.Item>
  );
}
