import {
  ON_MATERIAL_VALUE_CHANGE,
  ON_MATERIAL_FORM_OPEN,
  ON_MATERIAL_FORM_SUBMIT,
  ON_MATERIAL_CREATE,
} from '../actions/types';

const INITIAL_STATE = {
  date: '',
  provider: '',
  invoice: '',
  materials: [],
  loading: false,
  setReaload: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_MATERIAL_VALUE_CHANGE:
      if (action.payload === 'materials') {
        state.materials.push({ ...action.payload });
      }
      return { ...state, ...action.payload };
    case ON_MATERIAL_FORM_OPEN:
      return { INITIAL_STATE };
    case ON_MATERIAL_FORM_SUBMIT:
      return { ...state, loading: true };
    case ON_MATERIAL_CREATE:
      return { INITIAL_STATE, loading: false };
    default:
      return state;
  }
};
