import {
  ON_CATEGORY_READ,
  ON_CATEGORY_READING,
  ON_CATEGORY_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = {
  categories: [],
  loading: false,
  setReload: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_CATEGORY_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_CATEGORY_READING:
      return { ...state, loading: true };
    case ON_CATEGORY_READ:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
