import React, { useEffect, useState } from 'react';
import ListWork from '../../components/Works/ListWorks';
import { onWorksActiveRead, onWorksRead } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import Spin from '../../components/Spin';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Button, Switch } from 'antd';
import Modal from '../../components/Modal';
import FormWork from '../../components/Works/FormWork';

export default function Works() {
  const dispatch = useDispatch();
  const [reload, setReload] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [viewWorksActive, setViewWorksActive] = useState(true);

  const { loading } = useSelector((store) => store.workList);

  useEffect(() => {
    dispatch(onWorksRead(viewWorksActive));
    setReload(false);
  }, [viewWorksActive, reload]);

  const addWorkModal = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nueva obra');
    setModalContent(
      <FormWork setIsVisibleModal={setIsVisibleModal} setReload={setReload} />
    );
  };

  if (loading) {
    return <Spin />;
  }
  return (
    <div>
      <PageHeader
        title={viewWorksActive ? 'Obras Activas' : 'Obras Inactivas'}
        back={false}
        preAction={
          <Switch
            defaultChecked
            onChange={() => setViewWorksActive(!viewWorksActive)}
            checked={viewWorksActive}
          />
        }
        actions={
          <Button type="primary" onClick={addWorkModal}>
            Nueva Obra
          </Button>
        }
      />

      <ListWork setReload={setReload} active={viewWorksActive} />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="75%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
