import React, { useState, useEffect } from 'react';
import { getHistoryMessagesApi } from '../../api/message';
import { useSelector } from 'react-redux';
import MessageList from '../../components/Message/MessageList';
import { ROLES } from '../../utils/constants';
import { notification } from 'antd';
import Spin from '../../components/Spin';
import { getAccessTokenApi } from '../../api/auth';

export default function MessageHistory(props) {
  const [messages, setMessages] = useState();
  const { type, role } = useSelector((store) => store.userData.user);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      getHistoryMessagesApi(token)
        .then((response) => {
          setMessages(response.messages);
        })
        .catch(() => {});
    };
    getData();
  }, []);

  if (!messages) {
    return <Spin />;
  }

  return (
    <div>{messages && <MessageList messages={messages} history={true} />}</div>
  );
}
