import { ON_DATA_DOLLAR_READING, ON_DATA_DOLLAR_READ } from '../actions/types';

const INITIAL_STATE = {
  dollarOficial: 0,
  dollarBlue: 0,
  variationOficial: '',
  variationBlue: '',
  lastUpdate: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_DATA_DOLLAR_READING:
      return { ...state, loading: true };
    case ON_DATA_DOLLAR_READ:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
