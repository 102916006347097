import React, { useState } from 'react';
import ListProviders from '../../components/Provider/ListProviders/ListProviders';
import PageHeader from '../../components/PageHeader/PageHeader';
import { Button, Tooltip } from 'antd';
import { PlusOutlined, PlusSquareOutlined } from '@ant-design/icons';
import ProviderForm from '../../components/Provider/ProviderForm/ProviderForm';
import Modal from '../../components/Modal/Modal';
import MaterialForm from '../../components/Materials/MaterialForm/MaterialForm';

export default function Providers() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);

  const addProvider = (e) => {
    e.stopPropagation();
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo proovedor');
    setModalContent(<ProviderForm setIsVisibleModal={setIsVisibleModal} />);
  };

  const addMaterialsModal = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo pedido de materiales');
    setModalContent(
      <MaterialForm setIsVisibleModalForm={setIsVisibleModal} provider={true} />
    );
  };

  return (
    <div>
      <PageHeader
        title={'Proovedores'}
        back={false}
        actions={
          <div>
            <Tooltip title="Nuevo proovedor">
              <Button type="link" onClick={(e) => addProvider(e)} size="large">
                <PlusOutlined />
              </Button>
            </Tooltip>
            <Button type="default" onClick={addMaterialsModal}>
              Compra de Materiales
            </Button>
          </div>
        }
      />
      <ListProviders />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={'75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
