import React from 'react';
import ListMaterials from '../../components/Materials/ListMaterials';
import { Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

export default function Materials() {
  const { materials, loading } = useSelector((store) => store.materialList);

  if (loading && materials.length === 0) {
    return (
      <Spin
        style={{ width: '100%', padding: '200px 0' }}
        size="large"
        indicator={<LoadingOutlined />}
      />
    );
  }
  return (
    <div>
      <ListMaterials />
    </div>
  );
}
