import { basePath, apiVersion } from './config';

export function addOrderApi(token, orderData) {
  const url = `${basePath}/${apiVersion}/order`;

  const params = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(orderData),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getOrders(token, work) {
  const url = `${basePath}/${apiVersion}/orders/${work}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getQuantityOrders(token, work) {
  const url = `${basePath}/${apiVersion}/quantity-orders/${work}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getOrderDetail(token, order) {
  const url = `${basePath}/${apiVersion}/order-detail/${order}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function getOrderDetailByProvider(token, order) {
  const url = `${basePath}/${apiVersion}/order-detail-provider/${order}`;

  const params = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function changeStatusOrder({ token, order, status, user, type }) {
  const url = `${basePath}/${apiVersion}/order-status/${order}?status=${status}&user=${user}&type=${type}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function addSendOrderApi({
  token,
  order,
  itemId,
  material,
  work,
  sendData,
  rollback,
}) {
  const url = `${basePath}/${apiVersion}/order-send/${order}/${itemId}/${material}/${work}?rollback=${rollback}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(sendData),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function addRecivedAllOrderApi({ token, order, recivedData }) {
  const url = `${basePath}/${apiVersion}/order-recived/${order}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(recivedData),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function addRecivedOrderApi({
  token,
  order,
  recivedData,
  itemId,
  material,
  sendItemId,
}) {
  const url = `${basePath}/${apiVersion}/order-item-recived/${order}/${itemId}/${sendItemId}/${material}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(recivedData),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function closedOrderApi({ token, items, user }) {
  const url = `${basePath}/${apiVersion}/order-closed/${user}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(items),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}

export function declineOrderApi({ token, items, user }) {
  const url = `${basePath}/${apiVersion}/order-decline/${user}`;

  const params = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
    body: JSON.stringify(items),
  };

  return fetch(url, params)
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      return result;
    })
    .catch((err) => {
      return err.message;
    });
}
