import React, { useEffect } from 'react';
import ListMovements from '../../components/Movements/ListMovements';
import { onMovementsRead } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export default function Movements() {
  const dispatch = useDispatch();
  const { movements, loading } = useSelector((store) => store.movementList);

  useEffect(() => {
    dispatch(onMovementsRead());
  }, []);

  if (loading && movements.length === 0) {
    return (
      <Spin
        style={{ width: '100%', padding: '200px 0' }}
        size="large"
        indicator={<LoadingOutlined />}
      />
    );
  }
  return (
    <div>
      <ListMovements />
    </div>
  );
}
