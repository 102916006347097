import { combineReducers, createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { loadState, saveState } from './config';
import UserDataReducer from './UserDataReducer';
import ToolFormReducer from './ToolFormReducer';
import ToolListReducer from './ToolListReducer';
import { ON_LOGOUT_FINISHED } from '../actions/types';
import WorkListReducer from './WorkListReducer';
import MovementListReducer from './MovementListReducer';
import DollarDataReducer from './DollarDataReducer';
import WorkFormReducer from './WorkFormReducer';
import CategoryDataReducer from './CategoryDataReducer';
import ProviderDataReducer from './ProviderDataReducer';
import MaterialFormReducer from './MaterialFormReducer';
import ProductDataReducer from './ProductDataReducer';
import MaterialsListReducer from './MaterialsListReducer';
import MaterialsWorkReducer from './MaterialsWorkReducer';
import ClienDataReducer from './ClientDataReducer';
import PhoneDataReducer from './PhoneDataReducer';
import MessageDataReducer from './MessageDataReducer';

const initialData = loadState() || {};

const reducers = combineReducers({
  userData: UserDataReducer,
  toolList: ToolListReducer,
  toolForm: ToolFormReducer,
  workList: WorkListReducer,
  workForm: WorkFormReducer,
  movementList: MovementListReducer,
  dollarData: DollarDataReducer,
  categoryData: CategoryDataReducer,
  providerData: ProviderDataReducer,
  materialForm: MaterialFormReducer,
  productData: ProductDataReducer,
  materialList: MaterialsListReducer,
  materialWorkList: MaterialsWorkReducer,
  clientData: ClienDataReducer,
  phoneData: PhoneDataReducer,
  messageData: MessageDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === ON_LOGOUT_FINISHED) {
    state = undefined;
  }

  return reducers(state, action);
};

const store = createStore(
  rootReducer,
  initialData,
  applyMiddleware(ReduxThunk)
);

store.subscribe(function () {
  saveState(store.getState());
});

export default store;
