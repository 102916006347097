import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Checkbox, Row, Col, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { onMessageCreate } from '../../actions';
import PermissionsAsigner from '../PermissionsAsigner';

export default function MessageForm(props) {
  const { setIsVisibleModal, type, report, turn } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [messageForm] = Form.useForm();

  const { name, lastname, email, _id } = useSelector(
    (store) => store.userData.user
  );

  useEffect(() => {
    messageForm.resetFields();
  });

  const onFinish = (values) => {
    setLoading(true);
    values.author = `${name} ${lastname}`;
    values.userType = type;
    values.type = report ? 'Reporte error' : !values.type ? type : 'Enfermero';
    values.userType = type;
    values.report = report;
    values.email = email;
    values.turn = turn ? true : false;
    values.userId = turn || _id;
    dispatch(onMessageCreate(values, setIsVisibleModal, type));
    setLoading(false);
  };

  return (
    <Form form={messageForm} onFinish={onFinish}>
      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Debe ingresar un mensaje' }]}
      >
        <Input.TextArea
          placeholder={
            report ? 'Explique el problema o error...' : 'Mensaje...'
          }
          autoSize={{ minRows: 4 }}
        />
      </Form.Item>

      {!report && (
        <Row gutter={24}>
          <Col>
            <Form.Item name="fixed" valuePropName="checked">
              <Checkbox
                onChange={() => messageForm.setFieldsValue({ type: false })}
              >
                <Tooltip title="Al fijar el mensaje solo lo podra resolver usted">
                  Fijar mensaje
                </Tooltip>
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Form.Item>
        <Button
          type="default"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={loading}
          disabled={loading}
        >
          Enviar Mensaje
        </Button>
      </Form.Item>
    </Form>
  );
}
