import React, { useState, useCallback, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
  Avatar,
  notification,
} from 'antd';
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import NoAvatar from '../../../assets/img/no-avatar.png';
import {
  updateUserApi,
  getSignatureApi,
  uploadSignatureApi,
} from '../../../api/user';
import { getAccessTokenApi } from '../../../api/auth';
import { ROLES } from '../../../utils/constants';
import { useSelector } from 'react-redux';
import './EditUserForm.scss';

export default function EditUserForm(props) {
  const { user, setIsVisibleModal, setReloadUsers } = props;
  const [signature, setSignature] = useState(null);
  const [userData, setUserData] = useState({});
  const { type } = useSelector((store) => store.userData.user);
  const [userForm] = Form.useForm();

  useEffect(() => {
    setUserData({
      name: user.name,
      lastname: user.lastname,
      email: user.email,
      role: user.role,
      signature: user.signature,
    });
  }, [user]);

  useEffect(() => {
    userForm.resetFields();
  }, [user]);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();
      if (user.signature) {
        getSignatureApi(user.signature, token).then((response) => {
          setSignature(response.url);
        });
      } else {
        setSignature(null);
      }
    };
    getData();
  }, [user]);

  useEffect(() => {
    if (signature) {
      setUserData({ ...userData, signature: signature.file });
    }
  }, [signature]);

  const updateUser = async (values) => {
    const token = await getAccessTokenApi();
    let signatureUpdate = userData;
    let userUpdate = values;

    if (typeof signatureUpdate.signature === 'object') {
      uploadSignatureApi(token, signatureUpdate.signature, user._id).then(
        (response) => {
          signatureUpdate.signature = response.signatureName;
          updateUserApi(token, userUpdate, user._id).then((result) => {
            notification['success']({ message: result.message });
          });
        }
      );
    } else {
      updateUserApi(token, userUpdate, user._id).then((result) => {
        notification['success']({ message: result.message });
      });
    }

    setIsVisibleModal(false);
    setReloadUsers(true);
  };

  return (
    <div className="edit-user-form">
      <UploadSignature signature={signature} setSignature={setSignature} />
      <EditForm
        user={user}
        updateUser={updateUser}
        userForm={userForm}
        type={type}
      />
    </div>
  );
}

function UploadSignature(props) {
  const { signature, setSignature } = props;
  const [signatureUrl, setSignatureUrl] = useState(null);

  useEffect(() => {
    if (signature) {
      if (signature.preview) {
        setSignatureUrl(signature.preview);
      } else {
        setSignatureUrl(signature);
      }
    } else {
      setSignatureUrl(null);
    }
  }, [signature]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      setSignature({ file, preview: URL.createObjectURL(file) });
    },
    [setSignature]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/jpeg, image/png',
    noKeyboard: true,
    disabled: true,
    onDrop,
  });

  return (
    <>
      <div style={{ textAlign: 'center', marginBottom: '1%' }}>
        <span>Firma Digital</span>{' '}
      </div>
      <div className="upload-signature" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Avatar shape="square" size={150} src={NoAvatar} />
        ) : (
          <Avatar
            shape="square"
            size={150}
            src={signatureUrl ? signatureUrl : NoAvatar}
          />
        )}
      </div>
    </>
  );
}

function EditForm(props) {
  const { user, updateUser, userForm, type } = props;
  const { Option } = Select;

  return (
    <Form form={userForm} className="form-edit" onFinish={updateUser}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="name"
            initialValue={user.name}
            rules={[{ required: true, message: 'Debe cargar un nombre' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Nombre" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastname"
            initialValue={user.lastname}
            rules={[{ required: true, message: 'Debe cargar un apellido' }]}
          >
            <Input prefix={<UserOutlined />} placeholder="Apelldo" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="email"
            initialValue={user.email}
            rules={[{ required: true, message: 'Debe cargar un email' }]}
          >
            <Input prefix={<MailOutlined />} placeholder="Correo electronico" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="role"
            initialValue={user.role}
            rules={[{ required: true, message: 'Debe seleccionar un rol' }]}
          >
            <Select placeholder="Selecciona un rol"></Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Button type="default" htmlType="submit" className="button-submit">
          Actualzar Usuario
        </Button>
      </Form.Item>
    </Form>
  );
}
