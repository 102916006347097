import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
  onProviderCreate,
  onProviderValueChange,
  onProviderUpdate,
} from '../../../actions';

export default function ProviderForm(props) {
  const { setIsVisibleModal, provider } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [provider]);

  const onFinish = (values) => {
    form.resetFields();
    if (!provider) {
      dispatch(onProviderCreate(values, setIsVisibleModal));
    } else {
      dispatch(onProviderUpdate(provider._id, values, setIsVisibleModal));
    }
  };

  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="nameProvider"
              initialValue={provider ? provider.name : ''}
              rules={[{ required: true, message: 'Debe cargar un nombre' }]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="direction"
              initialValue={provider ? provider.direction : ''}
            >
              <Input placeholder="Dirección" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="phone"
              initialValue={provider ? provider.phone : ''}
            >
              <Input placeholder="Teléfono" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="mail" initialValue={provider ? provider.mail : ''}>
              <Input placeholder="E-Mail" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                style={{ marginTop: 0, width: '100%' }}
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
