import {
  ON_TOOL_CREATE,
  ON_TOOL_FORM_OPEN,
  ON_TOOL_READ,
  ON_TOOL_READING,
  ON_TOOL_FORM_SUBMIT,
  ON_TOOL_VALUE_CHANGE,
} from './types';
import {
  getToolsApi,
  addToolApi,
  updateToolApi,
  deleteToolApi,
  newMovementToolApi,
} from '../api/tool';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onToolValueChange = (payload) => {
  return { type: ON_TOOL_VALUE_CHANGE, payload };
};

export const onToolFormOpen = () => {
  return { type: ON_TOOL_FORM_OPEN };
};

export const onToolsRead = () => (dispatch) => {
  dispatch({ type: ON_TOOL_READING });
  const token = getAccessTokenApi();

  return getToolsApi(token).then((response) => {
    dispatch({ type: ON_TOOL_READ, payload: response.tools });
  });
};

export const onToolCreate = (toolData, setIsVisibleModal) => (dispatch) => {
  dispatch({ type: ON_TOOL_FORM_SUBMIT });
  const token = getAccessTokenApi();

  return addToolApi(token, toolData)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        setIsVisibleModal(false);
        dispatch({
          type: ON_TOOL_VALUE_CHANGE,
          payload: { setReloadTool: true },
        });
        dispatch({ type: ON_TOOL_CREATE });
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onToolUpdate = (id, toolData, setIsVisibleModal) => (dispatch) => {
  dispatch({ type: ON_TOOL_FORM_SUBMIT });
  const token = getAccessTokenApi();

  updateToolApi(token, id, toolData)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        setIsVisibleModal(false);
        dispatch({
          type: ON_TOOL_VALUE_CHANGE,
          payload: { setReloadTool: true },
        });
        dispatch({ type: ON_TOOL_CREATE });
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onToolDelete = (id) => (dispatch) => {
  dispatch({ type: ON_TOOL_FORM_SUBMIT });
  const token = getAccessTokenApi();

  deleteToolApi(token, id)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        dispatch({
          type: ON_TOOL_VALUE_CHANGE,
          payload: { setReloadTool: true },
        });
        notification['success']({ message: response.message });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onMovementTool = (toolData, setIsVisibleModal) => (dispatch) => {
  const token = getAccessTokenApi();

  newMovementToolApi(token, toolData)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        setIsVisibleModal(false);
        dispatch({
          type: ON_TOOL_VALUE_CHANGE,
          payload: { setReloadTool: true },
        });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};
