import { ON_DATA_DOLLAR_READ, ON_DATA_DOLLAR_READING } from './types';
import { getDollarDataApi } from '../api/dollar';
import moment from 'moment';

export const onDollarDataRead = () => (dispatch) => {
  dispatch({ type: ON_DATA_DOLLAR_READING });

  return getDollarDataApi().then((response) => {
    var dollarOficial = response.data?.oficial.value_sell;
    var dollarBlue = response.data?.blue.value_sell;

    var lastUpdate = moment(response.data?.last_update).format('DD/MM HH:mm');
    var variationBlue = '';

    dispatch({
      type: ON_DATA_DOLLAR_READ,
      payload: { dollarOficial, dollarBlue, lastUpdate, variationBlue },
    });
  });
};
