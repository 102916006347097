import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  Button,
  Form,
  Divider,
  DatePicker,
  Select,
  Radio,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import locale from 'antd/es/date-picker/locale/ar_EG';
import { onWorkCreate, onClientsRead, onWorkUpdate } from '../../../actions';
import './FormWork.scss';
import moment from 'moment';

export default function FormWork(props) {
  const { work, setIsVisibleModal, setReload } = props;
  const { clients } = useSelector((store) => store.clientData);
  const { dollarOficial } = useSelector((store) => store.dollarData);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [work]);

  useEffect(() => {
    dispatch(onClientsRead());
  }, []);

  const onFinish = (values) => {
    if (!work) {
      dispatch(onWorkCreate(values, setIsVisibleModal, setReload));
    } else {
      dispatch(onWorkUpdate(work._id, values, setIsVisibleModal, setReload));
    }
  };

  return (
    <div>
      <Form form={form} name="workForm" className="form" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                { required: true, message: 'Debe cargar el nombre de la obra' },
              ]}
              initialValue={work ? work.name : ''}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="direction"
              initialValue={work ? work.direction : ''}
            >
              <Input placeholder="Dirección" />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Comitente</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="nameClient"
              initialValue={work && work.client && work.client._id}
            >
              <Select
                placeholder="Seleccione un comitente/cliente.. (*)"
                showSearch
                optionFilterProp="children"
                onChange={(e, data) =>
                  form.setFieldsValue({ workType: data.title })
                }
              >
                {clients.map((client, index) => (
                  <Select.Option
                    key={index}
                    title={client.type}
                    value={client._id}
                  >
                    {client.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="workType"
              initialValue={work && work.client ? work.client.type : ''}
            >
              <Input placeholder="Tipo de obra (*)" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="type" initialValue={work ? work.type : ''}>
              <Input placeholder="Tipo (*)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="participation"
              initialValue={work ? work.participation : ''}
            >
              <Input placeholder="Participación (*)" suffix={'%'} />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Fechas</Divider>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="biddingDate"
              initialValue={work ? moment(work.biddingDate) : ''}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Fecha de licitación (*)"
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="contractDate"
              initialValue={
                work ? work.contractDate && moment(work.contractDate) : ''
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Fecha de contrato"
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="startEndDate"
              initialValue={
                work
                  ? work.startDate && [
                      moment(work.startDate),
                      moment(work.endDate),
                    ]
                  : ''
              }
            >
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                placeholder={['Fecha de incio', 'Fecha de fin']}
                format="DD-MM-YYYY"
                locale={locale}
                separator="-"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="monthDate"
              initialValue={
                work ? work.monthDate && moment(work.monthDate) : ''
              }
            >
              <DatePicker
                style={{ width: '100%' }}
                picker="month"
                placeholder="Mes base"
                format="MM-YYYY"
                locale={locale}
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Montos</Divider>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="redetermina"
              label="Redetermina"
              initialValue={work ? work.redetermina : ''}
            >
              <Radio.Group>
                <Radio value={true}>Si</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="originalContract"
              initialValue={work ? work.originalContract : ''}
            >
              <Input
                prefix={<span>$</span>}
                placeholder="Contrato original (*)"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="dollarContract"
              initialValue={work ? work.dollarContract : ''}
            >
              <Input
                prefix={<span>U$</span>}
                placeholder="Contrato original U$D (*)"
                onChange={(e) =>
                  form.setFieldsValue({
                    dollarContractUpdate:
                      e.target.value * parseInt(dollarOficial),
                  })
                }
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="dollarContractUpdate"
              initialValue={
                work ? work.dollarContract * parseInt(dollarOficial) : ''
              }
            >
              <Input
                placeholder="Monto total actualizado (U$D Oficial)"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Button type="primary" htmlType="submit" className="button-submit">
          {work ? 'Actualizar Obra' : 'Cargar Obra'}
        </Button>
      </Form>
    </div>
  );
}
