import {
  ON_PRODUCT_READ,
  ON_PRODUCT_READING,
  ON_PRODUCT_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = {
  products: [],
  loading: false,
  setReload: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_PRODUCT_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_PRODUCT_READING:
      return { ...state, loading: true };
    case ON_PRODUCT_READ:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
