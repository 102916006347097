import React, { useEffect } from 'react';
import { Form, Row, Col, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { onCategoryCreate } from '../../actions';

export default function ToolCategoryForm(props) {
  const { setIsVisibleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);

  const onFinish = (values) => {
    form.resetFields();
    dispatch(onCategoryCreate(values, setIsVisibleModal));
  };

  return (
    <div>
      <Form form={form} className="form" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={16}>
            <Form.Item
              name="categoryName"
              initialValue=""
              rules={[{ required: true, message: 'Debe cargar una categoria' }]}
            >
              <Input placeholder="Nombre (*)" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%' }}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
