import {
  ON_PRODUCT_READ,
  ON_PRODUCT_READING,
  ON_PRODUCT_VALUE_CHANGE,
  ON_CATEGORY_READ,
  ON_CATEGORY_READING,
  ON_CATEGORY_VALUE_CHANGE,
  ON_PROVIDER_READ,
  ON_PROVIDER_READING,
  ON_PROVIDER_VALUE_CHANGE,
  ON_CLIENT_READING,
  ON_CLIENT_READ,
  ON_CLIENT_VALUE_CHANGE,
} from './types';
import {
  getProductsApi,
  deleteProductApi,
  getCategoriesApi,
  addCategoryApi,
  getProvidersApi,
  addProviderApi,
  deleteProviderApi,
  updateProviderApi,
  deleteCategoryApi,
  addClientApi,
  updateClientApi,
  deleteClientApi,
  getClientsApi,
} from '../api/configuration';
import { getAccessTokenApi } from '../api/auth';

import { notification } from 'antd';

export const onProductValueChange = (payload) => {
  return { type: ON_PRODUCT_VALUE_CHANGE, payload };
};

export const onProductsRead = () => (dispatch) => {
  dispatch({ type: ON_PRODUCT_READING });

  getProductsApi()
    .then((response) => {
      dispatch({ type: ON_PRODUCT_READ, payload: response.products });
    })
    .catch(() => {});
};

export const onProductDelete = (id) => (dispatch) => {
  const token = getAccessTokenApi();

  deleteProductApi(token, id)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        dispatch({
          type: ON_PRODUCT_VALUE_CHANGE,
          payload: { setReload: true },
        });
        notification['success']({ message: response.message });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onCategoryValueChange = (payload) => {
  return { type: ON_CATEGORY_VALUE_CHANGE, payload };
};

export const onCategoriesRead = () => (dispatch) => {
  dispatch({ type: ON_CATEGORY_READING });

  getCategoriesApi()
    .then((response) => {
      dispatch({ type: ON_CATEGORY_READ, payload: response.categories });
    })
    .catch(() => {});
};

export const onCategoryCreate = (name, setIsVisibleModal) => (dispatch) => {
  const token = getAccessTokenApi();

  addCategoryApi(token, name)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        dispatch({
          type: ON_CATEGORY_VALUE_CHANGE,
          payload: { setReload: true },
        });
        setIsVisibleModal(false);
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onCategoryDelete = (id) => (dispatch) => {
  const token = getAccessTokenApi();

  deleteCategoryApi(token, id)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        dispatch({
          type: ON_CATEGORY_VALUE_CHANGE,
          payload: { setReload: true },
        });
        notification['success']({ message: response.message });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onProviderValueChange = (payload) => {
  return { type: ON_PROVIDER_VALUE_CHANGE, payload };
};

export const onProvidersRead = () => (dispatch) => {
  dispatch({ type: ON_PROVIDER_READING });

  getProvidersApi()
    .then((response) => {
      dispatch({ type: ON_PROVIDER_READ, payload: response.providers });
    })
    .catch(() => {});
};

export const onProviderCreate =
  (providerData, setIsVisibleModalProvider) => (dispatch) => {
    const token = getAccessTokenApi();

    addProviderApi(token, providerData)
      .then((response) => {
        if (response.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          dispatch({
            type: ON_PROVIDER_VALUE_CHANGE,
            payload: { reload: true },
          });
          setIsVisibleModalProvider(false);
        }
      })
      .catch((err) => {
        notification['error']({ message: 'Error del servidor' });
      });
  };

export const onProviderUpdate =
  (id, providerData, setIsVisibleModal) => (dispatch) => {
    const token = getAccessTokenApi();

    updateProviderApi(token, id, providerData)
      .then((response) => {
        if (response.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          dispatch({
            type: ON_PROVIDER_VALUE_CHANGE,
            payload: { setReload: true },
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onProviderDelete = (id) => (dispatch) => {
  const token = getAccessTokenApi();

  deleteProviderApi(token, id)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        dispatch({
          type: ON_PROVIDER_VALUE_CHANGE,
          payload: { setReload: true },
        });
        notification['success']({ message: response.message });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onClientValueChange = (payload) => {
  return { type: ON_CLIENT_VALUE_CHANGE, payload };
};

export const onClientsRead = () => (dispatch) => {
  dispatch({ type: ON_CLIENT_READING });

  getClientsApi()
    .then((response) => {
      dispatch({ type: ON_CLIENT_READ, payload: response.clients });
    })
    .catch(() => {});
};

export const onClientCreate = (clientData, setIsVisibleModal) => (dispatch) => {
  const token = getAccessTokenApi();

  addClientApi(token, clientData)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        dispatch({
          type: ON_CLIENT_VALUE_CHANGE,
          payload: { setReload: true },
        });
        setIsVisibleModal(false);
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};

export const onClientUpdate =
  (id, clientData, setIsVisibleModal) => (dispatch) => {
    const token = getAccessTokenApi();

    updateClientApi(token, id, clientData)
      .then((response) => {
        if (response.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          dispatch({
            type: ON_CLIENT_VALUE_CHANGE,
            payload: { setReload: true },
          });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onClientDelete = (id) => (dispatch) => {
  const token = getAccessTokenApi();

  deleteClientApi(token, id)
    .then((response) => {
      if (response.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        dispatch({
          type: ON_CLIENT_VALUE_CHANGE,
          payload: { setReload: true },
        });
        notification['success']({ message: response.message });
      }
    })
    .catch((err) => {
      notification['error']({ message: 'Error del servidor' });
    });
};
