import {
  ON_MATERIAL_CREATE,
  ON_MATERIAL_FORM_OPEN,
  ON_MATERIAL_FORM_SUBMIT,
  ON_MATERIAL_READ,
  ON_MATERIAL_READING,
  ON_MATERIAL_VALUE_CHANGE,
  ON_MATERIAL_WORK_READ,
  ON_MATERIAL_WORK_READING,
} from './types';
import {
  addMaterialApi,
  getMaterials,
  addMaterialReceptionApi,
  getMaterialsReceptionApi,
} from '../api/material';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onMaterialValueChange = (payload) => {
  return { type: ON_MATERIAL_VALUE_CHANGE, payload };
};

export const onMaterialFormOpen = () => {
  return { type: ON_MATERIAL_FORM_OPEN };
};

export const onMaterialCreate = ({ materialData, setIsVisibleModal }) => {
  const token = getAccessTokenApi();

  return addMaterialApi(token, materialData)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        setIsVisibleModal(false);
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onMaterialReceptionCreate = (materialData, setIsVisibleModal) => {
  const token = getAccessTokenApi();

  return addMaterialReceptionApi(token, materialData)
    .then((response) => {
      if (response?.code !== 200) {
        notification['error']({ message: response.message });
      } else {
        notification['success']({ message: response.message });
        setIsVisibleModal(false);
      }
    })
    .catch(() => {
      notification['error']({
        message: 'Error del servidor, intente mas tarde.',
      });
    });
};

export const onMaterialsRead = () => (dispatch) => {
  dispatch({ type: ON_MATERIAL_READING });
  const token = getAccessTokenApi();

  return getMaterials(token).then((response) => {
    dispatch({ type: ON_MATERIAL_READ, payload: response.materials });
  });
};

export const onMaterialsWorkRead = () => (dispatch) => {
  dispatch({ type: ON_MATERIAL_WORK_READING });
  const token = getAccessTokenApi();

  return getMaterialsReceptionApi(token).then((response) => {
    dispatch({ type: ON_MATERIAL_WORK_READ, payload: response.materials });
  });
};
