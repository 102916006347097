import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  DatePicker,
  Select,
  Tree,
  Button,
  notification,
} from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import './ToolMovementForm.scss';
import InputQuantity from './InputQuantity';
import { getWorkName } from '../../../api/work';
import { onMovementTool, onWorksActiveRead } from '../../../actions';
export default function ToolMovementForm(props) {
  const { setIsVisibleModal } = props;
  const [quantity, setQuantity] = useState({ tools: [] });
  const [workData, setWorkData] = useState([]);
  const [data, setData] = useState({
    date: moment(new Date(), 'DD/MM/YYYY').toISOString(),
  });
  const dispatch = useDispatch();
  const { name, lastname } = useSelector((store) => store.userData.user);
  const tools = useSelector((store) => store.toolList.tools);
  const worksActive = useSelector((store) => store.workList.worksActive);

  useEffect(() => {
    dispatch(onWorksActiveRead());
  }, []);

  const toolQuantity = (data) => {
    var index = quantity.tools.findIndex((i) => i.id === data.id);
    if (index === -1) {
      setQuantity({ tools: [...quantity.tools, data] });
    } else {
      if (data.quantity > 0) {
        setQuantity({
          tools: [
            ...quantity.tools.slice(0, index),
            Object.assign({}, quantity.tools[index], {
              quantity: data.quantity,
            }),
            ...quantity.tools.slice(index + 1),
          ],
        });
      } else {
        setQuantity({
          tools: [...quantity.tools.slice(0, index)],
        });
      }
    }
  };

  var treeData = [];

  worksActive.forEach((work, index) => {
    treeData[index] = {
      key: work._id,
      title: work.name,
      children: [{ title: 'Herramientas', key: work.name, children: [] }],
      disabled: data.work === work._id ? true : false,
    };

    tools.forEach((tool) => {
      if (tool.work._id === work._id) {
        treeData[index].children[0].children.push({
          title: (
            <div>
              {tool.mark
                ? `${tool.name} (cantidad: ${tool.quantity} marca: ${tool.mark})`
                : `${tool.name} (cantidad: ${tool.quantity})`}
              <InputQuantity
                tool={tool}
                toolQuantity={toolQuantity}
                disabled={data.work === work._id ? true : false}
              />
            </div>
          ),
          checkable: false,
          key: [tool._id],
        });
      }
    });
  });

  const addMovement = (e) => {
    e.preventDefault();
    dispatch(
      onMovementTool(
        {
          date: data.date,
          work: data.work,
          user: `${name} ${lastname}`,
          workName: data.workName,
          ids: quantity.tools,
        },
        setIsVisibleModal
      )
    );

    setData({ date: moment(new Date(), 'DD/MM/YYYY').toISOString() });
  };
  return (
    <div>
      <Form onFinish={addMovement}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item>
              <DatePicker
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                placeholder="Fecha del movimiento (*)"
                value={moment(new Date())}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item>
              <Select
                placeholder="Seleccione la nueva ubicación.. (*)"
                value={data.work}
                onChange={(value, name) => {
                  setData({
                    ...data,
                    work: value,
                    workName: name.props.children,
                  });
                }}
              >
                {worksActive.map((work, index) => (
                  <Select.Option key={index} value={work._id}>
                    {work.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item>
            <Tree treeData={treeData} />
          </Form.Item>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            Confirmar Movimiento
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
