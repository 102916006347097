import React, { useState, useEffect } from 'react';
import {
  Form,
  Row,
  Col,
  Button,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Divider,
  notification,
} from 'antd';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import {
  onProvidersRead,
  onProviderValueChange,
  onProductsRead,
  onProductValueChange,
  onMaterialReceptionCreate,
  onWorksActiveRead,
} from '../../../actions';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

export default function MaterialReceptionForm(props) {
  const { setIsVisibleModalForm } = props;
  const [workData, setWorkData] = useState([]);
  const [materialProvider, setMaterialProvider] = useState([]);
  const [quantity, setQuantity] = useState(null);

  const { providers } = useSelector((store) => store.providerData);
  const setReloadProvider = useSelector(
    (store) => store.providerData.setReload
  );
  const { products } = useSelector((store) => store.productData);
  const setReloadProduct = useSelector((store) => store.productData.setReload);

  const { materials } = useSelector((store) => store.materialList);
  const materialsWork = useSelector(
    (store) => store.materialWorkList.materials
  );
  const worksActive = useSelector((store) => store.workList.worksActive);

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length === 0 || setReloadProduct) {
      dispatch(onProductsRead());
      dispatch(onProductValueChange({ setReload: false }));
    }
  }, [setReloadProduct]);

  useEffect(() => {
    dispatch(onWorksActiveRead());
  }, [materialProvider]);

  useEffect(() => {
    if (providers.length === 0 || setReloadProvider) {
      dispatch(onProvidersRead());
      dispatch(onProviderValueChange({ setReload: false }));
    }
  }, [setReloadProvider]);

  const providerSelected = (e) => {
    const materialProvider1 = products.map((product) => {
      var quantity = 0;
      materials.map((material) =>
        material.provider._id === e
          ? material.materials.map((i) =>
              i.quantity > 0 &&
              i.name ==
                `${product.category} - ${product.name} (${product.unit})`
                ? (quantity += i.quantity)
                : null
            )
          : null
      );
      materialsWork.map((material) =>
        material.provider._id === e
          ? material.materials.map((i) =>
              i.name ==
              `${product.category} - ${product.name} (${product.unit})`
                ? (quantity -= i.quantity)
                : null
            )
          : null
      );
      const name = `${product.category} - ${product.name} (${product.unit})`;
      if (quantity > 0) {
        return { name, quantity, provider: e };
      }
    });

    var filtered = materialProvider1.filter(Boolean);

    var merged = [].concat.apply([], filtered);

    var result = [];
    merged.forEach(function (item) {
      var idx;
      var found = result.some(function (el, i) {
        idx = el.name === item.name ? i : null;
        return el.name === item.name;
      });

      if (!found) {
        result.push(item);
      } else if (idx !== null) {
        for (var k in Object.keys(item)) {
          if (item.hasOwnProperty(k)) {
            result[idx][k] = item[k];
          }
        }
      }
    });
    setMaterialProvider(result);

    if (result.length === 0) {
      form.resetFields();
      notification['info']({
        message: 'No hay materiales en este proovedor',
      });
    } else if (materialProvider.length > 0 && quantity !== null) {
      form.resetFields();
      setMaterialProvider([]);
      notification['info']({
        message: 'Si cambia de proovedor debe volver a cargar los datos.',
      });
    }
  };

  const onFinish = (values) => {
    var result = [];
    values.materials.forEach(function (item) {
      var idx;
      var found = result.some(function (el, i) {
        idx = el.name === item.name ? i : null;
        return el.name === item.name;
      });

      if (!found) {
        result.push(item);
      } else if (idx !== null) {
        for (var k in Object.keys(item)) {
          if (item.hasOwnProperty(k)) {
            result[idx][k] = item[k];
          }
        }
      }
    });

    if (result.length === values.materials.length) {
      form.resetFields();

      dispatch(onMaterialReceptionCreate(values, setIsVisibleModalForm));
    } else {
      notification['error']({
        message:
          'Error al guardar. Esta seleccionando el mismo material por separado.',
      });
    }
  };

  return (
    <div>
      <Form name="form" form={form} className="form" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="date"
              initialValue={moment()}
              rules={[{ required: true, message: 'Debe cargar la fecha' }]}
            >
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Fecha de alta (*)"
                format="DD-MM-YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="refer"
              initialValue=""
              rules={[
                { required: true, message: 'Debe cargar el Nro de remito' },
              ]}
            >
              <Input placeholder="Nro de Remito (*)" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="provider"
              rules={[{ required: true, message: 'Debe elegir un proovedor' }]}
            >
              <Select
                placeholder="Seleccione un proovedor.. (*)"
                showSearch
                optionFilterProp="children"
                onChange={(e) => providerSelected(e)}
                initialValue=""
              >
                {providers.map((provider, index) => (
                  <Select.Option key={index} value={provider._id}>
                    {provider.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="work"
              rules={[{ required: true, message: 'Debe elegir una obra' }]}
            >
              <Select
                placeholder="Seleccione una obra.. (*)"
                showSearch
                optionFilterProp="children"
              >
                {worksActive.map((work, index) => (
                  <Select.Option key={index} value={work._id}>
                    {work.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider>Datos Materiales</Divider>
        <Form.List name="materials">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <Row gutter={24} key={index}>
                    <Col span={16}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'name']}
                        fieldKey={[field.fieldKey, 'name']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe elegir un material',
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%' }}
                          placeholder="Seleccione un material.. (*)"
                          optionFilterProp="children"
                          onChange={(e, name) => setQuantity(name.children[2])}
                        >
                          {materialProvider.map((material, index) => (
                            <Select.Option key={index} value={material.name}>
                              {material.name} Cantidad: {material.quantity}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        {...field}
                        name={[field.name, 'quantity']}
                        fieldKey={[field.fieldKey, 'quantity']}
                        rules={[
                          {
                            required: true,
                            message: 'Debe cargar la cantidad',
                          },
                        ]}
                      >
                        <InputNumber
                          style={{ width: '100%' }}
                          placeholder="Cantidad (*)"
                          max={quantity}
                          min={1}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={1} style={{ paddingLeft: '0' }}>
                      <Button
                        type="link"
                        size="large"
                        onClick={() => {
                          remove(field.name);
                        }}
                      >
                        <MinusCircleOutlined />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <Form.Item style={{ textAlign: 'right' }}>
                  {fields.length < 20 &&
                  materialProvider.length > 0 &&
                  fields.length !== materialProvider.length ? (
                    <Button
                      type="primary"
                      shape="circle"
                      onClick={() => {
                        add();
                      }}
                      size="large"
                    >
                      <PlusCircleOutlined />
                    </Button>
                  ) : null}
                </Form.Item>
              </div>
            );
          }}
        </Form.List>

        <Form.Item>
          <Button className="button-submit" type="primary" htmlType="submit">
            Guardar Nueva Recepción de Materiales
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
