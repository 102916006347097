import LayoutAdmin from '../layouts/LayoutAdmin';
import Home from '../pages/Home/Home';
import SignIn from '../pages/SignIn';
import Users from '../pages/Users';
import Error404 from '../pages/Error404';
import Phones from '../pages/Phones';
import Protocolo from '../pages/Protocolos';

import Tools from '../pages/Tools';
import Works from '../pages/Works';
import ViewWork from '../pages/ViewWork/ViewWork';
import Movements from '../pages/Movements';
import ViewMovement from '../components/Movements/ViewMovement';
import Materials from '../pages/Materials';
import ViewMaterialOrder from '../components/Materials/ViewMaterialOrder';
import ViewMaterialReception from '../components/Materials/ViewMaterialReception';
import Configurations from '../pages/Configurations';
import Orders from '../pages/Orders';
import OrderDetail from '../pages/OrderDetail/OrderDetail';
import Providers from '../pages/Providers/Providers';
import ViewProvider from '../pages/Providers/ViewProvider';
import ListMaterialItems from '../components/Materials/ListMaterialsItems/ListMaterialItems';
import MessageHistory from '../pages/MessageHistory';

const routes = [
  {
    path: '/',
    exact: false,

    component: LayoutAdmin,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home,
      },
      {
        path: '/login',
        exact: true,
        component: SignIn,
      },
      {
        path: '/users',
        exact: true,
        component: Users,
      },
      {
        path: '/phones',
        exact: true,
        component: Phones,
      },

      {
        path: '/protocolo',
        exact: true,
        component: Protocolo,
      },
      {
        path: '/tools',
        exact: true,
        component: Tools,
      },
      {
        path: '/works',
        exact: true,
        component: Works,
      },
      {
        path: '/view-work/:id',
        exact: true,
        component: ViewWork,
      },
      {
        path: '/view-work-orders/:id',
        exact: true,
        component: Orders,
      },
      {
        path: '/view-order-detail/:id',
        exact: true,
        component: OrderDetail,
      },
      {
        path: '/providers',
        exact: true,
        component: Providers,
      },
      {
        path: '/view-provider/:id/:name',
        exact: true,
        component: ViewProvider,
      },
      {
        path: '/view-provider-items/:provider/:name',
        exact: true,
        component: ListMaterialItems,
      },
      {
        path: '/view-work-materials/:id/:name',
        exact: true,
        component: ViewMaterialOrder,
      },
      {
        path: '/movements',
        exact: true,
        component: Movements,
      },
      {
        path: '/download-movement/:id',
        exact: true,
        component: ViewMovement,
      },
      {
        path: '/materials',
        exact: true,
        component: Materials,
      },
      {
        path: '/view-material-order/:id/:name',
        exact: true,
        component: ViewMaterialOrder,
      },
      {
        path: '/view-material-reception/:id',
        exact: true,
        component: ViewMaterialReception,
      },
      {
        path: '/configurations',
        exact: true,
        component: Configurations,
      },
      {
        path: '/message-history',
        exact: true,
        component: MessageHistory,
      },
      {
        component: Error404,
      },
    ],
  },
];

export default routes;
