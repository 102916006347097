import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Button, Modal as ModalAntd, Input } from 'antd';
import Modal from '../../Modal';
import ProviderForm from '../ProviderForm';
import {
  onProviderDelete,
  onProvidersRead,
  onProviderValueChange,
} from '../../../actions';
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
  InfoOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import '../../../scss/list.scss';
import Spin from '../../Spin/Spin';
import { Link } from 'react-router-dom';

export default function ListProviders() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const { providers, setReload, loading } = useSelector(
    (store) => store.providerData
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onProvidersRead());
  }, [setReload]);

  const editProvider = (provider) => {
    setIsVisibleModal(true);
    setModalTitle('Editando proovedor');
    setModalContent(
      <ProviderForm provider={provider} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const showDeleteConfirmProvider = (provider) => {
    const id = provider._id;
    ModalAntd.confirm({
      title: 'Eliminando proovedor',
      content: (
        <span>
          Estas seguro que quieres eliminar {provider.name}?
          <br />
          <span style={{ color: 'red' }}>
            ATENCION!
            <br />
            Antes de eliminar, asegurarse que el proovedor no tenga materiales
            asociados.
          </span>
        </span>
      ),
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onProviderDelete(id));
      },
    });
  };

  if (loading) return <Spin />;

  return (
    <div>
      <List
        className="list"
        itemLayout="horizontal"
        dataSource={providers}
        renderItem={(provider) => (
          <Provider
            provider={provider}
            showDeleteConfirmProvider={showDeleteConfirmProvider}
            editProvider={editProvider}
          />
        )}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cargando nueva categoria' ? '50%' : '75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Provider(props) {
  const { provider, showDeleteConfirmProvider, editProvider } = props;
  return (
    <List.Item
      actions={[
        <Link to={`/view-provider/${provider._id}/${provider.name}`}>
          <Button type="link">
            <InfoCircleOutlined />
          </Button>
        </Link>,
        <Button type="link" onClick={() => editProvider(provider)}>
          <EditOutlined />
        </Button>,
        <Button
          type="link"
          style={{ color: 'red' }}
          onClick={() => showDeleteConfirmProvider(provider)}
        >
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={
          !provider.direction
            ? ` ${provider.name}`
            : `${provider.name} - Dirección: ${provider.direction}`
        }
        description={
          !provider.phone && !provider.mail
            ? null
            : `Teléfono: ${provider.phone} - e-mail: ${provider.mail}`
        }
      />
    </List.Item>
  );
}
