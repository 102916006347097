import React from 'react';
import { Button, Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Header } from 'antd/lib/layout/layout';
import './PageHeader.scss';
export default function PageHeader({ back = true, title, actions, preAction }) {
  return (
    <div className="header">
      <div>
        {back && (
          <Button
            type="link"
            size="middle"
            style={{ left: 0 }}
            onClick={() => {
              window.history.back();
            }}
          >
            <ArrowLeftOutlined /> Atras
          </Button>
        )}
        {preAction && preAction}
        <span className="header__title">{title}</span>
      </div>
      {actions}
    </div>
  );
}
