import React, { useEffect, useState } from 'react';
import { getMaterialsByProviderApi } from '../../api/material';
import { getAccessTokenApi } from '../../api/auth';
import Modal from '../../components/Modal/Modal';
import { Button } from 'antd';
import MaterialForm from '../../components/Materials/MaterialForm/MaterialForm';
import PageHeader from '../../components/PageHeader/PageHeader';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import './ViewProvider.scss';
import ListMaterialBuys from '../../components/Materials/ListMaterialsItems/ListMaterialItems';
import { Link } from 'react-router-dom';

export default function ViewProvider(props) {
  const { id, name } = props.match.params;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [providerMaterials, setProviderMaterials] = useState([]);

  useEffect(() => {
    let token = getAccessTokenApi();
    getMaterialsByProviderApi({ token, provider: id })
      .then((response) => {
        setProviderMaterials(response.providerMaterials);
      })
      .catch((err) => {});
  }, [isVisibleModal]);

  const addMaterialsModal = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo pedido de materiales');
    setModalContent(
      <MaterialForm setIsVisibleModalForm={setIsVisibleModal} provider={id} />
    );
  };

  return (
    <div>
      <PageHeader
        title={name}
        actions={
          <div>
            <Link
              to={{
                pathname: `/view-provider-items/${id}/${name}`,
                hash: '#send',
              }}
            >
              <Button type="link">Ver envios</Button>
            </Link>

            <Link
              to={{
                pathname: `/view-provider-items/${id}/${name}`,
                hash: '#buys',
              }}
            >
              <Button type="link">Ver compras</Button>
            </Link>
            <Button type="default" onClick={addMaterialsModal}>
              Nueva compra
            </Button>
          </div>
        }
      />
      <h1>Acopio en {name}</h1>
      <MaterialTable items={providerMaterials} />
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="75%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
