import React, { useState } from 'react';
import Logo from '../../assets/img/mini-logo.png';
import LogoNombre from '../../assets/img/mini-nombre.png';
import './MenuTop.scss';
import { Button, Row, Tooltip, Dropdown, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PoweroffOutlined,
  DownOutlined,
  SettingOutlined,
  MessageOutlined,
  HistoryOutlined,
  WarningOutlined,
  FileTextOutlined,
} from '@ant-design/icons';
import { onLogout } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import MessageForm from '../Message/MessageForm';
import Modal from '../Modal';

export default function MenuTop(props) {
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const { menuCollapsed, setMenuCollapsed } = props;

  const { name, lastname } = useSelector((store) => store.userData.user);

  const dispatch = useDispatch();
  const logoutUser = () => {
    dispatch(onLogout());
  };

  const addMessage = (report = false) => {
    setIsVisibleModalForm(true);
    setModalTitle(report ? 'Reportar problema' : 'Nuevo mensaje');
    setModalContent(
      <MessageForm
        setIsVisibleModal={setIsVisibleModalForm}
        type="Medico"
        report={report}
      />
    );
  };

  return (
    <div className="menu-top">
      <div className="menu-top-left">
        {!menuCollapsed ? (
          <img className="menu-top-left__logo" src={LogoNombre} alt="logo" />
        ) : (
          <img className="menu-top-left-mini__logo" src={Logo} alt="logo" />
        )}
        <Button type="link" onClick={() => setMenuCollapsed(!menuCollapsed)}>
          {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
      </div>
      <Row style={{ right: 0, position: 'absolute' }}>
        <div>
          <Dropdown
            style={{ padding: 0 }}
            trigger={['click']}
            overlay={
              <Menu theme="dark" mode="inline">
                <Menu.Item key="/message">
                  <Button
                    type="link"
                    onClick={() => addMessage()}
                    style={{
                      color: '#fff',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    <MessageOutlined />
                    <span className="navText">Dejar mensaje</span>
                  </Button>
                </Menu.Item>
                <Menu.Item key="/report">
                  <Button
                    type="link"
                    onClick={() => addMessage('report')}
                    style={{
                      color: '#fff',
                      width: '100%',
                      textAlign: 'left',
                    }}
                  >
                    <WarningOutlined />
                    <span className="navText" style={{ color: 'white' }}>
                      Reportar problema
                    </span>
                  </Button>
                </Menu.Item>
                <Menu.Item key="/configuration">
                  <Link to="/configuration">
                    <Button
                      type="link"
                      style={{
                        color: '#fff',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <SettingOutlined />
                      <span className="navText">Configuración</span>
                    </Button>
                  </Link>
                </Menu.Item>
                <Menu.Item key="/message-history">
                  <Link to="/message-history">
                    <Button
                      type="link"
                      style={{
                        color: '#fff',
                        width: '100%',
                        textAlign: 'left',
                      }}
                    >
                      <HistoryOutlined />{' '}
                      <span className="navText">Historial mensajes</span>
                    </Button>
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <Button type="link" style={{ paddingRight: 8 }}>
              <text>
                {name} {lastname}
              </text>
              <DownOutlined />
            </Button>
          </Dropdown>
          <Tooltip title="Cerrar sesión">
            <Button type="link" onClick={logoutUser}>
              <PoweroffOutlined />
            </Button>
          </Tooltip>
        </div>
      </Row>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width={modalTitle === `Formulario alta` ? '75%' : '50%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
