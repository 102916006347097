import React from 'react';
import { Card, Statistic, List } from 'antd';

export default function ProductWork(props) {
  const { product, work, materialsWork, search } = props;

  var quantity = 0;

  if (search) {
    materialsWork.map((material) =>
      material.work.name === work
        ? material.materials.map((i) =>
            i.name ===
              `${product.category} - ${product.name} (${product.unit})` &&
            product.category
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
              ? (quantity += i.quantity)
              : null
          )
        : null
    );
  } else {
    materialsWork.map((material) =>
      material.work.name === work
        ? material.materials.map((i) =>
            i.name === `${product.category} - ${product.name} (${product.unit})`
              ? (quantity += i.quantity)
              : null
          )
        : null
    );
  }

  if (search) {
    return quantity > 0 ? (
      <div style={{ margin: '10px' }}>
        <Card
          title={product.category}
          style={{ width: '350px', textAlign: 'center' }}
        >
          <Statistic
            style={{ width: '100%' }}
            title={product.name}
            value={quantity}
            valueStyle={
              quantity > 0 ? { color: '#3f8600' } : { color: '#cf1322' }
            }
            suffix={product.unit}
          />
        </Card>
      </div>
    ) : null;
  }

  return (
    <List.Item>
      <List.Item.Meta
        title={`${product.category} - ${product.name} `}
        description={`Cantidad: ${quantity} (${product.unit})`}
      />
    </List.Item>
  );
}
