import { Button, List } from 'antd';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { getMaterialsBuys, getMaterialsSend } from '../../../api/material';
import { getAccessTokenApi } from '../../../api/auth';
import Spin from '../../Spin';
import PageHeader from '../../PageHeader';

export default function ListMaterialItems(props) {
  const { provider, name } = props.match.params;
  const type = props.location.hash;
  const [items, setItems] = useState();

  useEffect(() => {
    let token = getAccessTokenApi();

    type === '#send'
      ? getMaterialsSend({ token, provider })
          .then((response) => {
            setItems(response.materialsSend);
          })
          .catch((err) => {})
      : getMaterialsBuys({ token, provider })
          .then((response) => {
            setItems(response.materialsBuys);
          })
          .catch((err) => {});
  }, []);

  if (!items) return <Spin />;

  return (
    <>
      <PageHeader
        title={`${name} ${type === '#send' ? '/ Envios' : '/ Compras'}`}
      />
      <List
        className="list"
        itemLayout="horizontal"
        dataSource={items}
        renderItem={(item) => (
          <Item item={item} type={type} providerName={name} />
        )}
      />
    </>
  );
}

function Item(props) {
  const { item, type, providerName } = props;
  const day = moment(item.date).format('DD');
  const month = moment(item.date).format('MMMM');
  const year = moment(item.date).format('YYYY');

  return (
    <List.Item
      actions={[
        <Link
          to={{
            pathname: `/view-material-order/${item._id}/${providerName}`,
            hash: type,
          }}
        >
          <Button type="primary">
            <InfoCircleOutlined />
          </Button>
        </Link>,
      ]}
    >
      <div className="list__date">
        <span style={{ marginRight: 2 }}>{day}</span>
        <span style={{ marginRight: 2 }}>{month}</span>
        <span>{year}</span>
      </div>
      <List.Item.Meta
        title={
          type === '#send'
            ? `Enviado a: ${item?.work?.name}`
            : `Nro de factura: ${item.invoice}`
        }
      />
    </List.Item>
  );

  return null;
}
