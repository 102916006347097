import React, { useEffect, useState } from 'react';
import {
  addRecivedAllOrderApi,
  changeStatusOrder,
  closedOrderApi,
  declineOrderApi,
  getOrderDetail,
  getOrderDetailByProvider,
} from '../../api/order';
import { getAccessTokenApi } from '../../api/auth';
import Spin from '../../components/Spin';
import PageHeader from '../../components/PageHeader';
import { Button, Col, Row, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Modal from '../../components/Modal';
import AdminOrderItem from '../../components/AdminOrderItem/AdminOrderItem';
import MaterialTable from '../../components/MaterialTable/MaterialTable';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function OrderDetail(props) {
  const { id } = props.match.params;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [reload, setReload] = useState(false);

  const { _id } = useSelector((store) => store.userData.user);

  useEffect(() => {
    let token = getAccessTokenApi();
    setLoading(true);
    props.location.hash === '#reception' ||
    props.location.hash === '#viewRecived'
      ? getOrderDetailByProvider(token, id)
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response?.message);
            } else {
              console.log(response);
              setItems(response?.orderDetail);
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          })
      : getOrderDetail(token, id)
          .then((response) => {
            if (response?.code !== 200) {
              message.error(response?.message);
            } else {
              setItems(response?.orderDetail);
            }
          })
          .catch(() => {
            message.error('Error del servidor, intente mas tarde.');
          });
    setLoading(false);
    setReload(false);
  }, [reload]);

  if (loading) return <Spin />;

  const adminItem = (material) => {
    setIsVisibleModal(true);
    setModalTitle('Gestionar item');
    setModalContent(
      <AdminOrderItem
        item={material}
        setIsVisibleModal={setIsVisibleModal}
        setReloadItem={setReload}
      />
    );
  };

  const changeStatus = async (status, type) => {
    let token = getAccessTokenApi();

    changeStatusOrder({ token, order: id, status, user: _id, type })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response?.message);
        } else {
          status === 'Aprobado'
            ? await changeStatusOrder({
                token,
                order: id,
                status: 'Pendiente envio',
                user: _id,
                type: 'send',
              }).then((response) => {
                if (response?.code !== 200) {
                  message.error(response?.message);
                }
                items[0]?.status === 'En curso' && window.history.back();
              })
            : setReload(true);
          message.success(response?.message);
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  const recivedAllOrder = async () => {
    const token = getAccessTokenApi();

    let uniqueMaterial = [...new Set(items.map((x) => x.material._id))];

    let recivedData = [];
    await uniqueMaterial.map((x, index) =>
      recivedData.push({ material: x, index: index })
    );

    await items.map(async (item, index) => {
      let a = await recivedData.find((a) => {
        return a.material === item.material._id;
      });
      a.send
        ? recivedData[a?.index].send.push({
            _id: item.material.send._id,
            provider: item.material.send.provider,
            quantity: item.material.send.quantity,
            quantityRecived: item.material.send.quantity,
          })
        : (recivedData[a?.index].send = [
            {
              _id: item.material.send._id,
              provider: item.material.send.provider,
              quantity: item.material.send.quantity,
              quantityRecived: item.material.send.quantity,
            },
          ]);
    });

    await addRecivedAllOrderApi({
      token,
      order: items[0]._id,
      recivedData: recivedData,
    })
      .then(async (response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
        }
      })
      .then(async () => {
        await changeStatusOrder({
          token,
          order: id,
          status: 'Recibido',
          user: _id,
          type: 'send',
        }).then((response) => {
          if (response?.code !== 200) {
            message.error(response?.message);
          }
          window.history.back();
          message.success(response?.message);
        });
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  const closedOrder = async () => {
    const token = getAccessTokenApi();

    await closedOrderApi({ token, items, user: _id })
      .then((response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          window.history.back();
        }
      })

      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  const declineOrder = async () => {
    const token = getAccessTokenApi();

    await declineOrderApi({ token, items, user: _id })
      .then((response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          window.history.back();
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  return (
    <div>
      <PageHeader
        title={`Detalle pedido ${items[0]?.numberOrder}`}
        actions={
          props.location.hash !== '#viewRecived' ? (
            props.location.hash === '#reception' ? (
              <div>
                <Button
                  type="link"
                  style={{ color: 'red' }}
                  onClick={async () => {
                    await changeStatus('Pendiente envio', 'send');
                    window.history.back();
                  }}
                >
                  Cancelar <CloseOutlined />
                </Button>
                {items[0]?.statusSend !== 'Recibido parcial' ? (
                  <Button
                    type="link"
                    style={{ color: 'green' }}
                    onClick={() => {
                      recivedAllOrder();
                    }}
                  >
                    Recibido Completo
                    <CheckOutlined />
                  </Button>
                ) : (
                  <Button
                    type="link"
                    style={{ color: 'green' }}
                    onClick={() => {
                      closedOrder();
                    }}
                  >
                    Cerrar orden
                    <CheckOutlined />
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <Button
                  type="link"
                  style={{ color: 'red' }}
                  onClick={() => {
                    declineOrder();
                  }}
                >
                  Rechazar <CloseOutlined />
                </Button>
                <Button
                  type="link"
                  style={{ color: 'green' }}
                  onClick={async () => {
                    await changeStatus(
                      items[0]?.status === 'En curso' ? 'Aprobado' : 'En curso'
                    );
                  }}
                >
                  {items[0]?.status === 'En curso'
                    ? 'Cerrar y Enviar'
                    : 'Aceptar y Gestionar'}
                  <CheckOutlined />
                </Button>
              </div>
            )
          ) : null
        }
      />

      <MaterialTable
        items={items}
        adminItem={props.location.hash === '#view' ? adminItem : false}
        receptionItem={props.location.hash === '#reception'}
        closedOrder={props.location.hash === '#viewRecived'}
        changeStatus={changeStatus}
      />

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="90%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
