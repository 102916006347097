import React, { useState } from 'react';
import {
  Table,
  Button,
  Modal as ModalAntd,
  Input,
  Checkbox,
  Tooltip,
  Row,
  Col,
  Card,
  Statistic,
  Collapse,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ToolForm from '../ToolForm';
import Modal from '../../Modal';
import moment from 'moment';
import { onToolsRead, onToolValueChange, onToolDelete } from '../../../actions';
import ToolMovementForm from '../ToolMovementForm';
import PermissionsAsigner from '../../PermissionsAsigner';
import { ROLES } from '../../../utils/constants';

import './TableTools.scss';
// import ExportJsonExcel from 'js-export-excel';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

export default function TableTools(props) {
  const { tools } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [search, setSearch] = useState('');
  const [fixed, setFixed] = useState(true);
  const dispatch = useDispatch();

  const columns = [
    {
      title: () => (
        <div>
          Nombre
          <Tooltip title={fixed ? 'Desfijar nombre' : 'Fijar nombre'}>
            <Checkbox
              defaultChecked
              style={{ marginLeft: 10 }}
              onChange={(e) => setFixed(e.target.checked)}
            />
          </Tooltip>
        </div>
      ),
      width: 400,
      dataIndex: 'name',
      key: 'name',
      fixed: fixed,
    },
    {
      title: 'Cantidad',
      width: 100,
      dataIndex: 'quantity',
      key: 'quanity',
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: 'Ubicación',
      width: 200,
      dataIndex: 'work',
      key: 'work',
      render: (record) => <span>{record[1]}</span>,
    },
    {
      title: 'Precio en U$S unitario',
      width: 200,
      dataIndex: 'dollarPrice',
      key: 'dollarPrice',
      render: (record) => <span>U${record}</span>,
      sorter: (a, b) => a.dollarPrice - b.dollarPrice,
    },
    {
      title: 'Precio en U$S total',
      width: 200,
      dataIndex: 'totalDollarPrice',
      key: 'totalDollarPrice',
      render: (record) => <span>U${record}</span>,
      sorter: (a, b) => a.totalDollarPrice - b.totalDollarPrice,
    },
    {
      title: 'Precio $ (Oficial)',
      width: 200,
      dataIndex: 'dollarPriceOficial',
      key: 'dollarPriceOficial',
      render: (record) => <span>${record}</span>,
      sorter: (a, b) => a.dollarPriceOficial - b.dollarPriceOficial,
    },
    {
      title: 'Precio $ (Blue)',
      width: 200,
      dataIndex: 'dollarPriceBlue',
      key: 'dollarPriceBlue',
      render: (record) => <span>${record}</span>,
    },
    {
      title: 'Marca',
      width: 200,
      dataIndex: 'mark',
      key: 'mark',
    },
    {
      title: 'Estado',
      width: 200,
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'Fecha',
      width: 150,
      dataIndex: 'date',
      key: 'date',
      render: (record) => <span>{moment(record).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Editar',
      key: 'editar',
      width: 85,
      fixed: 'right',
      render: (record) => (
        <PermissionsAsigner requiredRole={ROLES.ADMIN}>
          <Button type="primary" onClick={() => editToolModal(record)}>
            <EditOutlined />
          </Button>
        </PermissionsAsigner>
      ),
    },
    {
      title: 'Eliminar',
      key: 'eliminar',
      width: 85,
      fixed: 'right',
      render: (record) => (
        <PermissionsAsigner requiredRole={ROLES.ADMIN}>
          <Button type="danger" onClick={() => showDeleteConfirm(record)}>
            <DeleteOutlined />
          </Button>
        </PermissionsAsigner>
      ),
    },
  ];

  const addToolsModal = () => {
    setIsVisibleModal(true);
    setModalTitle('Cargando nueva herramienta');
    setModalContent(
      <ToolForm setIsVisibleModal={setIsVisibleModal} tool={null} />
    );
  };

  const editToolModal = (tool) => {
    setIsVisibleModal(true);
    setModalTitle('Actualizando Herramienta');
    setModalContent(
      <ToolForm tool={tool} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const addNewMovement = () => {
    dispatch(onToolsRead());
    setIsVisibleModal(true);
    setModalTitle('Movimiento de herramientas');
    setModalContent(<ToolMovementForm setIsVisibleModal={setIsVisibleModal} />);
  };

  const showDeleteConfirm = (tool) => {
    const id = tools[tool.key]._id;

    ModalAntd.confirm({
      title: 'Eliminando herramienta',
      content: `Estas seguro que quieres eliminar ${tool.name}?`,
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onToolDelete(id));
      },
    });
  };

  const { dollarOficial, dollarBlue } = useSelector(
    (store) => store.dollarData
  );

  const data = [];
  var totalPriceTools = 0;
  var totalPriceToolsOficial = 0;
  var totalPriceToolsBlue = 0;

  if (search) {
    totalPriceTools = 0;
    totalPriceToolsOficial = 0;
    totalPriceToolsBlue = 0;

    const toolsFilters = tools.filter(
      (tool) =>
        tool.name.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
        tool.work.name
          .toLowerCase()
          .trim()
          .includes(search.toLowerCase().trim()) ||
        (tool.invoice &&
          tool.invoice
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim())) ||
        (tool.refer &&
          tool.refer
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim())) ||
        (tool.category &&
          tool.category
            .toLowerCase()
            .trim()
            .includes(search.toLowerCase().trim()))
    );
    toolsFilters.map((tool, index) => {
      data.push({
        key: index,
        name: tool.name,
        price: tool.price,
        date: tool.date,
        category: tool.category,
        mark: tool.mark,
        work: [tool.work._id, tool.work.name],
        description: tool.description,
        state: tool.state,
        provider: tool.provider,
        invoice: tool.invoice,
        refer: tool.refer,
        quantity: tool.quantity,
        dollarPrice: tool.dollarPrice,
        totalDollarPrice: (tool.dollarPrice * tool.quantity).toFixed(2),
        dollarPriceOficial: (
          tool.dollarPrice *
          parseInt(dollarOficial) *
          tool.quantity
        ).toFixed(2),
        dollarPriceBlue: (
          tool.dollarPrice *
          parseInt(dollarBlue) *
          tool.quantity
        ).toFixed(2),
      });
      totalPriceTools += tool.dollarPrice * tool.quantity;
      totalPriceToolsOficial +=
        tool.dollarPrice * parseInt(dollarOficial) * tool.quantity;
      totalPriceToolsBlue +=
        tool.dollarPrice * parseInt(dollarBlue) * tool.quantity;
    });
  } else if (tools) {
    totalPriceTools = 0;
    totalPriceToolsOficial = 0;
    totalPriceToolsBlue = 0;
    tools.map((tool, index) => {
      data.push({
        key: index,
        id: tool._id,
        name: tool.name,
        price: tool.price,
        date: tool.date,
        category: tool.category,
        mark: tool.mark,
        work: [tool.work._id, tool.work.name],
        description: tool.description,
        state: tool.state,
        provider: tool.provider,
        invoice: tool.invoice,
        refer: tool.refer,
        quantity: tool.quantity,
        dollarPrice: tool.dollarPrice,
        totalDollarPrice: (tool.dollarPrice * tool.quantity).toFixed(2),
        dollarPriceOficial: (
          tool.dollarPrice *
          parseInt(dollarOficial) *
          tool.quantity
        ).toFixed(2),
        dollarPriceBlue: (
          tool.dollarPrice *
          parseInt(dollarBlue) *
          tool.quantity
        ).toFixed(2),
      });
      totalPriceTools += tool.dollarPrice * tool.quantity;
      totalPriceToolsOficial +=
        tool.dollarPrice * parseInt(dollarOficial) * tool.quantity;
      totalPriceToolsBlue +=
        tool.dollarPrice * parseInt(dollarBlue) * tool.quantity;
    });
  } else {
    dispatch(onToolsRead());
  }

  const downloadExcel = () => {
    var option = {};
    let dataTable = [];
    // if (data) {
    //   for (let i in data) {
    //     if (data) {
    //       let obj = {
    //         name: data[i].name,
    //         price: data[i].price,
    //         date: moment(data[i].date).format('DD/MM/YYYY'),
    //         category: data[i].category,
    //         mark: data[i].mark,
    //         work: data[i].work[1],
    //         description: data[i].description,
    //         state: data[i].state,
    //         provider: data[i].provider,
    //         invoice: data[i].invoice,
    //         quantity: data[i].quantity,
    //         dollarPrice: data[i].dollarPrice,
    //         totalDollarPrice: data[i].dollarPrice * data[i].quantity,
    //         dollarPriceOficial:
    //           data[i].dollarPrice * parseInt(dollarOficial) * data[i].quantity,
    //         dollarPriceBlue:
    //           data[i].dollarPrice * parseInt(dollarBlue) * data[i].quantity,
    //       };
    //       dataTable.push(obj);
    //     }
    //   }
    // }
    // option.fileName = `${moment(new Date()).format(
    //   'YYYY/MM/DD'
    // )} - Informe herramientas `;
    // option.datas = [
    //   {
    //     sheetData: dataTable,
    //     sheetName: 'Herramientas',
    //     sheetFilter: [
    //       'name',
    //       'category',
    //       'quantity',
    //       'mark',
    //       'date',
    //       'work',
    //       'state',
    //       'description',
    //       'invoice',
    //       'refer',
    //       'provider',
    //       'dollarPrice',
    //       'totalDollarPrice',
    //       'dollarPriceOficial',
    //       'dollarPriceBlue',
    //     ],
    //     sheetHeader: [
    //       'Nombre',
    //       'Categoria',
    //       'Cantidad',
    //       'Marca',
    //       'Fecha',
    //       'Ubicacion',
    //       'Estado',
    //       'Descripcion',
    //       'Nº Factura',
    //       'Nº Remito',
    //       'Proovedor',
    //       'Precio Unit U$D',
    //       'Precio U$D',
    //       'Precio $ (Oficial)',
    //       'Precio $ (Blue)',
    //     ],
    //   },
    // ];

    // var toExcel = new ExportJsonExcel(option);
    // toExcel.saveExcel();
  };

  return (
    <div className="tools">
      <Collapse defaultActiveKey={[1]} style={{ marginBottom: '10px' }}>
        <Collapse.Panel header="Tablero de Control" key={1}>
          <PermissionsAsigner requiredRole={ROLES.ADMIN}>
            <Row gutter={24} style={{ marginBottom: '20px' }}>
              <Col span={8}>
                <Card>
                  <Statistic
                    title={'Total del inventario en U$:'}
                    value={Math.trunc(totalPriceTools)}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<span>$</span>}
                    groupSeparator="."
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Total del inventario en $ (Oficial):"
                    value={Math.trunc(totalPriceToolsOficial)}
                    valueStyle={{ color: '#3f8600' }}
                    prefix={<span>$</span>}
                    groupSeparator="."
                  />
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Statistic
                    title="Total del inventario en $ (Blue):"
                    value={Math.trunc(totalPriceToolsBlue)}
                    valueStyle={{ color: '#032777' }}
                    prefix={<span>$</span>}
                    groupSeparator="."
                  />
                </Card>
              </Col>
            </Row>
          </PermissionsAsigner>

          <div className="tools__header">
            <Input.Search
              placeholder="Buscar herramientas..."
              style={{ left: 0, margin: '0 10px 0 0' }}
              onSearch={(value) => setSearch(value)}
              allowClear
            />
            <Button onClick={downloadExcel}>Exportar como Excel</Button>
            <Button onClick={addNewMovement}>Mover herramientas</Button>
            <PermissionsAsigner requiredRole={ROLES.ADMIN}>
              <Button onClick={addToolsModal}>Nueva Herramienta</Button>
            </PermissionsAsigner>
          </div>
        </Collapse.Panel>
      </Collapse>
      <div className="tools__table">
        <Table
          columns={columns}
          dataSource={data}
          scroll={{ x: 2220 }}
          pagination={{ pageSize: 50 }}
        />
      </div>
      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Movimiento de herramientas' ? '50%' : '75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
