import React, { useState, useEffect } from 'react';
import { InputNumber } from 'antd';

export default function InputQuantity(props) {
  const { tool, toolQuantity, disabled } = props;
  const [data, setData] = useState([]);

  const onPress = (value) => {
    setData({ id: tool._id, quantity: value });
    toolQuantity({
      id: tool._id,
      name: tool.name,
      work: tool.work.name,
      mark: tool.mark,
      quantity: value,
    });
  };

  return (
    <InputNumber
      size="small"
      min={0}
      max={tool.quantity}
      style={{ width: 50, marginLeft: 15 }}
      value={data.quantity}
      onChange={(value) => onPress(value)}
      disabled={disabled}
    />
  );
}
