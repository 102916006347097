// User types
export const ON_LOGOUT_FINISHED = 'on_logout_finished';
export const ON_USER_READ = 'on_user_read';
export const ON_USER_READING = 'on_user_reading';
export const ON_USER_VALUE_CHANGE = 'on_user_value_change';

// Phone types
export const ON_PHONE_READ = 'ON_PHONE_READ';
export const ON_PHONE_READING = 'ON_PHONE_READING';

// Message types
export const ON_MESSAGE_READ = 'ON_MESSAGE_READ';
export const ON_MESSAGE_READING = 'ON_MESSAGE_READING';

// Tool types
export const ON_TOOL_READING = 'ON_TOOL_READING';
export const ON_TOOL_READ = 'ON_TOOL_READ';
export const ON_TOOL_VALUE_CHANGE = 'ON_TOOL_VALUE_CHANGE';
export const ON_TOOL_FORM_OPEN = 'ON_TOOL_FORM_OPEN';
export const ON_TOOL_FORM_SUBMIT = 'ON_TOOL_FORM_SUBMIT';
export const ON_TOOL_CREATE = 'ON_TOOL_CREATE';

// Work types
export const ON_WORK_READING = 'ON_WORK_READING';
export const ON_WORK_READ = 'ON_WORK_READ';
export const ON_WORK_VALUE_CHANGE = 'ON_WORK_VALUE_CHANGE';
export const ON_WORK_FORM_OPEN = 'ON_WORK_FORM_OPEN';
export const ON_WORK_FORM_SUBMIT = 'ON_WORK_FORM_SUBMIT';
export const ON_WORK_CREATE = 'ON_WORK_CREATE';
export const ON_WORK_ACTIVE_READING = 'ON_WORK_ACTIVE_READING';
export const ON_WORK_ACTIVE_READ = 'ON_WORK_ACTIVE_READ';

// Movement types
export const ON_MOVEMENT_READING = 'ON_MOVEMENT_READING';
export const ON_MOVEMENT_READ = 'ON_MOVEMENT_READ';
export const ON_MOVEMENT_VALUE_CHANGE = 'ON_MOVEMENT_VALUE_CHANGE';
export const ON_MOVEMENT_FORM_OPEN = 'ON_MOVEMENT_FORM_OPEN';
export const ON_MOVEMENT_FORM_SUBMIT = 'ON_MOVEMENT_FORM_SUBMIT';
export const ON_MOVEMENT_CREATE = 'ON_MOVEMENT_CREATE';
export const ON_MOVEMENT_TOOL_VALUE_CHANGE = 'ON_MOVEMENT_TOOL_VALUE_CHANGE';

// Data dollar types
export const ON_DATA_DOLLAR_READING = 'ON_DATA_DOLLAR_READING';
export const ON_DATA_DOLLAR_READ = 'ON_DATA_DOLLAR_READ';

// Categories types
export const ON_CATEGORY_READING = 'ON_CATEGORY_READING';
export const ON_CATEGORY_READ = 'ON_CATEGORY_READ';
export const ON_CATEGORY_VALUE_CHANGE = 'ON_CATEGORY_VALUE_CHANGE';

// Providers types
export const ON_PROVIDER_READ = 'ON_PROVIDER_READ';
export const ON_PROVIDER_READING = 'ON_PROVIDER_READING';
export const ON_PROVIDER_VALUE_CHANGE = 'ON_PROVIDER_VALUE_CHANGE';

// Products types
export const ON_PRODUCT_READ = 'ON_PRODUCT_READ';
export const ON_PRODUCT_READING = 'ON_PRODUCT_READING';
export const ON_PRODUCT_VALUE_CHANGE = 'ON_PRODUCT_VALUE_CHANGE';

// Clients types
export const ON_CLIENT_READ = 'ON_CLIENT_READ';
export const ON_CLIENT_READING = 'ON_CLIENT_READING';
export const ON_CLIENT_VALUE_CHANGE = 'ON_CLIENT_VALUE_CHANGE';

//Materials types
export const ON_MATERIAL_READING = 'ON_MATERIAL_READING';
export const ON_MATERIAL_READ = 'ON_MATERIAL_READ';
export const ON_MATERIAL_VALUE_CHANGE = 'ON_MATERIAL_VALUE_CHANGE';
export const ON_MATERIAL_FORM_OPEN = 'ON_MATERIAL_FORM_OPEN';
export const ON_MATERIAL_FORM_SUBMIT = 'ON_MATERIAL_FORM_SUBMIT';
export const ON_MATERIAL_CREATE = 'ON_MATERIAL_CREATE';
export const ON_MATERIAL_WORK_READ = 'ON_MATERIAL_WORK_READ';
export const ON_MATERIAL_WORK_READING = 'ON_MATERIAL_WORK_READING';
export const ON_MATERIAL_WORK_VALUE_CHANGE = 'ON_MATERIAL_WORK_VALUE_CHANGE';
