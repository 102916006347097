import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ProductWork from '../../components/Works/ProductWork';
import {
  List,
  Input,
  Collapse,
  Descriptions,
  Statistic,
  Layout,
  Button,
  Tooltip,
  Col,
  Row,
  Tag,
  Divider,
} from 'antd';
import moment from 'moment';
import { onMaterialsWorkRead } from '../../actions';
import './ViewWork.scss';
import PageHeader from '../../components/PageHeader';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import MaterialForm from '../../components/Materials/MaterialForm';
import Modal from '../../components/Modal';
import Card from '../../components/Card';
import { Link } from 'react-router-dom';
import { getQuantityOrders } from '../../api/order';
import { getAccessTokenApi } from '../../api/auth';
import { statusCustomize } from '../../utils/constants';
import { getMaterialsByCategoryApi } from '../../api/material';

export default function ViewWork(props) {
  const { id } = props.match.params;
  const [search, setSearch] = useState();
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [ordersByStatus, setOrdersByStatus] = useState([]);
  const [quantity, setQuantity] = useState();
  const [materialsByCategory, setMaterialsByCategory] = useState();
  const [reload, setReload] = useState(false);

  const works = useSelector((store) => store.workList.works);

  useEffect(() => {
    let token = getAccessTokenApi();

    getQuantityOrders(token, id).then((response) => {
      setOrdersByStatus(response.orders);
      setQuantity(response.quantity);
    });

    getMaterialsByCategoryApi({ token, work: id })
      .then((response) => {
        setMaterialsByCategory(response.categoryMaterials);
      })
      .catch((err) => {});
    setReload(false);
  }, [reload]);

  var work = {};
  works.map((i) => {
    if (i._id == id) {
      work = i;
    }
  });

  const addMaterialsModal = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo pedido de materiales');
    setModalContent(
      <MaterialForm
        setIsVisibleModalForm={setIsVisibleModalForm}
        work={work._id}
        setReload={setReload}
      />
    );
  };

  return (
    <div>
      <PageHeader
        title={work.name}
        actions={
          <Button type="primary" onClick={addMaterialsModal}>
            Nuevo pedido <PlusOutlined />
          </Button>
        }
      />
      <Row gutter={[24, 24]} className="view-work__cards">
        <Col xs={24} sm={12} md={8} lg={8}>
          <Card
            title="Pedidos"
            extra={
              <Tooltip title="Gestionar pedidos">
                <Link to={`/view-work-orders/${work._id}`}>
                  <Button type="link">
                    <InfoCircleOutlined />
                  </Button>
                </Link>
              </Tooltip>
            }
            child={
              <div>
                Cantidad de pedidos: {quantity}
                {ordersByStatus.map((order) => (
                  <>
                    <Divider />
                    <Tag
                      color={
                        statusCustomize[
                          order?._id?.statusSend
                            ? order?._id?.statusSend
                            : order?._id.status
                        ]?.color
                      }
                    >
                      {
                        statusCustomize[
                          order?._id?.statusSend
                            ? order?._id?.statusSend
                            : order?._id.status
                        ]?.icon
                      }{' '}
                      {order?._id.status}
                      {order?._id?.statusSend
                        ? ` - ${order?._id?.statusSend}`
                        : ''}
                      : {order.quantity}
                    </Tag>
                  </>
                ))}
              </div>
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card
            title="Materiales en obra"
            extra={
              <Tooltip title="Ver historico">
                <Link
                  to={{
                    pathname: `/view-work-materials/${work._id}/${work.name}`,
                    hash: '#work',
                  }}
                >
                  <Button type="link">
                    <InfoCircleOutlined />
                  </Button>
                </Link>
              </Tooltip>
            }
            child={
              <div>
                {materialsByCategory?.map((category) => (
                  <>
                    <Divider />
                    <h4>
                      {category?._id}: {category?.quantity}
                    </h4>
                  </>
                ))}
              </div>
            }
          ></Card>
        </Col>
      </Row>

      <Collapse defaultActiveKey={1}>
        {/* <Collapse.Panel key={1} header="Información">
          <Descriptions
            title="Comitente"
            bordered
            layout="vertical"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label="Nombre">
              {work.client && work.client.name}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo de obra">
              {work.client && work.client.type}
            </Descriptions.Item>
            <Descriptions.Item label="Tipo">{work.type}</Descriptions.Item>
            <Descriptions.Item label="Participación">
              {work.participation} %
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Fechas"
            bordered
            layout="vertical"
            column={{ xxl: 5, xl: 5, lg: 5, md: 3, sm: 2, xs: 1 }}
            style={{ paddingTop: '10px' }}
          >
            <Descriptions.Item label="Licitación">
              {moment(work.biddingDate).format('DD/MM/YYYY')}
            </Descriptions.Item>
            <Descriptions.Item label="Contrato">
              {work.contractDate
                ? moment(work.contractDate).format('DD/MM/YYYY')
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Inicio">
              {work.startDate
                ? moment(work.startDate).format('DD/MM/YYYY')
                : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Finalización estimada">
              {work.endDate ? moment(work.endDate).format('DD/MM/YYYY') : '-'}
            </Descriptions.Item>
            <Descriptions.Item
              label="Mes Base"
              style={{ textTransform: 'capitalize' }}
            >
              {work.monthDate ? moment(work.monthDate).format('MMMM') : '-'}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Montos"
            bordered
            layout="vertical"
            column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 2, xs: 1 }}
            style={{ paddingTop: '10px' }}
          >
            <Descriptions.Item label="Redetermina">
              {work.redetermina ? 'Si' : 'No'}
            </Descriptions.Item>
            <Descriptions.Item label="Contrato Original">
              {work.originalContract}
            </Descriptions.Item>
            <Descriptions.Item label="Contrato Original U$D">
              {work.dollarContract}
            </Descriptions.Item>
            <Descriptions.Item label="Monto total actuliazado">
              Dolar Blue: U$D {parseFloat(dollarBlue) * work.dollarContract}
              <br />
              Dolar Oficial: U$D{' '}
              {parseFloat(dollarOficial) * work.dollarContract}
            </Descriptions.Item>
          </Descriptions>
        </Collapse.Panel> */}
      </Collapse>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width="75%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}
