import React from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Logo from '../../../assets/img/logo-black.png';
import './ViewMaterialReception.scss';

export default function ViewMaterialReception(props) {
  const { id } = props.match.params;
  const materialsWork = useSelector(
    (store) => store.materialWorkList.materials
  );

  var material = {};
  materialsWork.map((i) => {
    if (i._id == id) {
      material = i;
    }
  });

  return (
    <table
      cellspacing="1"
      cellpadding="3"
      border="1px"
      bgcolor="#fff"
      className="view-material-table"
    >
      <div className="view-material">
        <div className="view-material__title">
          <h1>Recepcion de materiales</h1>
          <img src={Logo} alt="logo" />
          <h1>Fecha:{moment(material.date).format('DD/MM/YYYY')}</h1>
        </div>
        <h1>Proovedor: {material.provider.name}</h1>
        <h1>Destino: {material.work.name}</h1>
        {/* <h1>Solicitante:{material.user}</h1> */}
        <h1>Responsable: </h1>
        <h1>Detalle:</h1>
        <table border={1} style={{ width: '100%' }}>
          <tr style={{ textAlign: 'center' }}>
            <th style={{ width: '10%' }}>Cantidad</th>
            <th style={{ width: '85%' }}>Descripcion</th>
            <th style={{ width: '5%' }}></th>
          </tr>
          {material.materials.map((material) => (
            <tr>
              <td style={{ textAlign: 'center', fontSize: '12px' }}>
                {material.quantity}
              </td>
              <td style={{ paddingLeft: 10, fontSize: '12px' }}>
                {material.name}
              </td>
              <td></td>
            </tr>
          ))}
        </table>
        <div className="view-material__footer">
          <h1>Recibe conforme: ___________________________</h1>
          <h1 style={{ marginLeft: '10px' }}>
            Aclaracion: ________________________________
          </h1>
        </div>
      </div>
    </table>
  );
}
