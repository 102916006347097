import React, { useEffect, useState } from 'react';
import { getMaterialUbications } from '../../api/material';
import { getAccessTokenApi } from '../../api/auth';
import {
  Button,
  Col,
  InputNumber,
  Row,
  Select,
  Form,
  Divider,
  message,
  Tooltip,
} from 'antd';
import {
  MinusCircleOutlined,
  PlusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import '../../scss/form.scss';
import Modal from '../Modal/Modal';
import MaterialForm from '../Materials/MaterialForm/MaterialForm';
import { addSendOrderApi } from '../../api/order';
import { toBeInTheDOM } from '@testing-library/jest-dom';

export default function AdminOrderItem({
  item,
  setIsVisibleModal,
  setReloadItem,
}) {
  const [materialUbications, setMaterialUbications] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalTitleForm, setModalTitleForm] = useState('');
  const [modalContentForm, setModalContentForm] = useState(null);
  const [reload, setReload] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    let token = getAccessTokenApi();
    getMaterialUbications({ token, material: item.material._id })
      .then((response) => {
        setMaterialUbications(response.material);
      })
      .catch((err) => {});

    setReload(false);
    form.setFieldsValue({
      send:
        item.material.send.length > 0
          ? item.material?.send
          : [{ quantity: item.material.quantity }],
    });
  }, [item, reload]);

  const addMaterialsModal = () => {
    setIsVisibleModalForm(true);
    setModalTitleForm('Cargando nuevo pedido de materiales');
    setModalContentForm(
      <MaterialForm
        setIsVisibleModalForm={setIsVisibleModalForm}
        provider={true}
        setReload={setReload}
      />
    );
  };

  const onFinish = (values) => {
    const token = getAccessTokenApi();

    addSendOrderApi({
      token,
      order: item._id,
      itemId: item.material.itemId,
      material: item.material._id,
      work: item.work,
      sendData: values === undefined ? { send: [] } : values,
      rollback: values === undefined ? true : false,
    })
      .then((response) => {
        if (response?.code !== 200) {
          message.error(response.message);
        } else {
          message.success(response.message);
          form.resetFields();
          setReloadItem(true);
          setIsVisibleModal(false);
        }
      })
      .catch(() => {
        message.error('Error del servidor, intente mas tarde.');
      });
  };

  return (
    <div>
      <h1>
        {item.material.name} - {item.material.name} ({item.material.unit})
      </h1>
      <Row gutter={[24, 24]}>
        <Col xs={24} md={8}>
          <Divider>Cantidad solicitada: {item.material.quantity}</Divider>
          <h4>Acopio de este material en: </h4>
          {materialUbications.map((m) => (
            <ul>
              <li>
                {m.provider.name} - Cantidad: {m?.quantity}
              </li>
            </ul>
          ))}
          <Button type="default" onClick={addMaterialsModal}>
            Compra de Materiales
          </Button>
        </Col>
        <Col xs={23} md={15}>
          <Divider>
            Datos Materiales{' '}
            {item.material?.send.length > 0 && (
              <Tooltip title="Deshacer pedido">
                <Button type="link" style={{ color: 'red' }}>
                  <StopOutlined onClick={() => onFinish()} />
                </Button>
              </Tooltip>
            )}
          </Divider>
          <Form form={form} className="form" name="form" onFinish={onFinish}>
            <Form.List name="send">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Row gutter={24} key={index}>
                        <Col sm={24} md={16}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'provider']}
                            fieldKey={[field.fieldKey, 'provider']}
                            rules={[
                              {
                                required: true,
                                message: 'Debe elegir un material',
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="Seleccione un material.. (*)"
                              optionFilterProp="children"
                              onChange={(e, extra) =>
                                setQuantity([...quantity, extra.key])
                              }
                              disabled={item.material?.send.length > 0}
                            >
                              {materialUbications.map((item, index) => (
                                <Select.Option
                                  key={item.quantity}
                                  value={item.provider._id}
                                  disabled={form
                                    .getFieldsValue()
                                    .send?.find(
                                      (p) => p?.provider === item.provider._id
                                    )}
                                >
                                  {item.provider.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col sm={24} md={7}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'quantity']}
                            fieldKey={[field.fieldKey, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: 'Debe cargar la cantidad',
                              },
                            ]}
                          >
                            <InputNumber
                              style={{ width: '100%' }}
                              placeholder="Cantidad (*)"
                              max={quantity[field.key]}
                              min={1}
                              disabled={item.material?.send.length > 0}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={1} style={{ paddingLeft: '0' }}>
                          <Button
                            type="link"
                            size="large"
                            onClick={() => {
                              remove(field.name);
                            }}
                            disabled={item.material?.send.length > 0}
                          >
                            <MinusCircleOutlined />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item style={{ textAlign: 'right' }}>
                      <Button
                        type="primary"
                        shape="circle"
                        onClick={() => {
                          add();
                        }}
                        size="large"
                        disabled={item.material?.send.length > 0}
                      >
                        <PlusOutlined />
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>
            <Form.Item>
              <Button
                className="button-submit"
                type="primary"
                htmlType="submit"
                disabled={item.material?.send.length > 0}
              >
                Guardar
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Modal
        title={modalTitleForm}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width="75%"
      >
        {modalContentForm}
      </Modal>
    </div>
  );
}
