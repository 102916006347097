import React, { useEffect, useState } from 'react';
import './ViewMaterialOrder.scss';
import moment from 'moment';
import 'moment/locale/es';
import Logo from '../../../assets/img/logo-black.png';
import { getMaterialBuy, getMaterialsByWorkApi } from '../../../api/material';
import { getAccessTokenApi } from '../../../api/auth';
import Spin from '../../Spin';
import PageHeader from '../../PageHeader';
import MaterialTable from '../../MaterialTable/MaterialTable';
export default function ViewMaterialOrder(props) {
  const { id, name } = props.match.params;

  const [material, setMaterial] = useState();
  let type = props.location.hash;

  useEffect(() => {
    let token = getAccessTokenApi();
    type === '#work'
      ? getMaterialsByWorkApi({ token, work: id })
          .then((response) => {
            setMaterial(response.workMaterials);
          })
          .catch((err) => {})
      : getMaterialBuy(token, id).then((response) => {
          setMaterial(response.material);
        });
  }, []);

  if (!material) return <Spin />;
  return (
    <>
      <PageHeader
        title={`${name} ${
          type === '#work'
            ? '/ Detalle materiales'
            : type === '#send'
            ? '/ Detalle envio'
            : '/ Detalle compra'
        }`}
      />
      <MaterialTable items={material} />
    </>
  );
}
