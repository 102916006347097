import {
  ON_TOOL_READ,
  ON_TOOL_READING,
  ON_TOOL_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = { tools: [], loading: false, setReloadTool: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_TOOL_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_TOOL_READING:
      return { ...state, loading: true };
    case ON_TOOL_READ:
      return { ...state, tools: action.payload, loading: false };
    default:
      return state;
  }
};
