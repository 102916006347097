import React from 'react';
import { Layout, Tabs, Row, Col } from 'antd';
import { Redirect } from 'react-router-dom';
import Logo from '../../assets/img/AmpinaWhite.png';
import RegisterForm from '../../components/RegisterForm';
import LoginForm from '../../components/LoginForm';
import { getAccessTokenApi } from '../../api/auth';
import './SignIn.scss';
import { useSelector } from 'react-redux';

export default function SignIn() {
  const { TabPane } = Tabs;

  if (getAccessTokenApi()) {
    return <Redirect to="/" />;
  }

  return (
    <div className="main-banner">
      <div className="main-banner__dark" />
      <Row>
        <Col md={2} />
        <Col xs={24} md={8}>
          <div className="main-banner__logo">
            <img src={Logo} alt="Logo" />
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={1} />
        <Col xs={24} md={10}>
          <div className="sign-in-content__tabs">
            <Tabs size="large" type="card">
              <TabPane tab={<span>Entrar</span>} key="1">
                <LoginForm />
              </TabPane>
              <TabPane tab={<span>Nuevo usuario</span>} key="2">
                <RegisterForm />
              </TabPane>
            </Tabs>
          </div>
        </Col>
      </Row>
    </div>
  );
}
