import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import { onToolsRead, onToolValueChange } from '../../actions';
import TableTools from '../../components/Tools/ListTools';
import './Tools.scss';
import { LoadingOutlined } from '@ant-design/icons';

export default function Tools() {
  const dispatch = useDispatch();
  const { tools, loading, setReloadTool } = useSelector(
    (store) => store.toolList
  );

  useEffect(() => {
    dispatch(onToolsRead());
    dispatch(onToolValueChange({ setReloadTool: false }));
  }, [setReloadTool]);

  if (loading && tools.length === 0) {
    return (
      <Spin
        style={{ width: '100%', padding: '200px 0' }}
        size="large"
        indicator={<LoadingOutlined />}
      />
    );
  }

  return <TableTools tools={tools} />;
}
