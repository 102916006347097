import {
  ON_USER_READ,
  ON_USER_READING,
  ON_USER_VALUE_CHANGE,
  ON_LOGOUT_FINISHED,
} from './types';
import { getUserDataApi } from '../api/user';
import { getAccessTokenApi, logout } from '../api/auth';

export const onUserValueChange = (payload) => {
  return { type: ON_USER_VALUE_CHANGE, payload };
};

export const onUserDataRead = () => async (dispatch) => {
  dispatch({ type: ON_USER_READING });
  const token = await getAccessTokenApi();

  return getUserDataApi(token)
    .then(async (response) => {
      await dispatch({
        type: ON_USER_READ,
        payload: response.userData,
      });
    })
    .catch(async () => {
      await dispatch(onLogout());
    });
};

export const onLogout = () => (dispatch) => {
  logout(true);
  dispatch({ type: ON_LOGOUT_FINISHED });
};
