import {
  ON_WORK_READ,
  ON_WORK_READING,
  ON_WORK_FORM_SUBMIT,
  ON_WORK_VALUE_CHANGE,
  ON_WORK_CREATE,
  ON_WORK_FORM_OPEN,
  ON_WORK_ACTIVE_READ,
  ON_WORK_ACTIVE_READING,
} from './types';
import { getAccessTokenApi } from '../api/auth';
import {
  getWorksApi,
  addWorkApi,
  getWorksActiveApi,
  updateWorkApi,
} from '../api/work';

import { notification } from 'antd';

export const onWorkValueChange = (payload) => {
  return { type: ON_WORK_VALUE_CHANGE, payload };
};

export const onWorkFormOpen = () => {
  return { type: ON_WORK_FORM_OPEN };
};

export const onWorksRead =
  (active = true) =>
  (dispatch) => {
    dispatch({ type: ON_WORK_READING });
    const token = getAccessTokenApi();

    return getWorksApi(token, active).then((response) => {
      dispatch({ type: ON_WORK_READ, payload: response.works });
    });
  };

export const onWorkCreate =
  (workData, setIsVisibleModal, setReload) => (dispatch) => {
    dispatch({ type: ON_WORK_FORM_SUBMIT });
    const token = getAccessTokenApi();

    return addWorkApi(token, workData)
      .then((response) => {
        if (response?.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          setReload(true);
          dispatch({ type: ON_WORK_CREATE });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onWorkUpdate =
  (id, workData, setIsVisibleModal, setReload) => (dispatch) => {
    const token = getAccessTokenApi();

    updateWorkApi(token, id, workData)
      .then((response) => {
        if (response.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          setIsVisibleModal(false);
          setReload(true);

          dispatch({ type: ON_WORK_CREATE });
        }
      })
      .catch(() => {
        notification['error']({
          message: 'Error del servidor, intente mas tarde.',
        });
      });
  };

export const onWorksActiveRead = () => (dispatch) => {
  dispatch({ type: ON_WORK_ACTIVE_READING });
  const token = getAccessTokenApi();

  return getWorksActiveApi(token).then((response) => {
    dispatch({ type: ON_WORK_ACTIVE_READ, payload: response.worksActive });
  });
};
