import React, { useState, useEffect } from 'react';
import Modal from '../../Modal';
import {
  Button,
  Tabs,
  List,
  Collapse,
  Input,
  Card,
  Statistic,
  Switch,
  Row,
} from 'antd';
import MaterialForm from '../MaterialForm';
import './ListMaterials.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
  onProvidersRead,
  onProductValueChange,
  onProductsRead,
  onMaterialsRead,
  onMaterialsWorkRead,
  onWorksActiveRead,
} from '../../../actions';
import moment from 'moment';
import 'moment/locale/es';
import ProductWork from '../../Works/ProductWork';
import { Link } from 'react-router-dom';
import MaterialReceptionForm from '../MaterialReceptionForm';
import { InfoCircleOutlined } from '@ant-design/icons';

export default function ListMaterials() {
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [viewProviders, setViewProviders] = useState(true);
  const [search, setSearch] = useState();

  const dispatch = useDispatch();

  const { providers } = useSelector((store) => store.providerData);
  const setReloadProvider = useSelector((store) => store.providerData.reload);

  const { materials } = useSelector((store) => store.materialList);
  const materialsWork = useSelector(
    (store) => store.materialWorkList.materials
  );
  const { worksActive } = useSelector((store) => store.workList);

  const { products } = useSelector((store) => store.productData);
  const setReloadProduct = useSelector((store) => store.productData.setReload);

  useEffect(() => {
    dispatch(onWorksActiveRead());
  }, [viewProviders]);

  useEffect(() => {
    dispatch(onProvidersRead());
    dispatch(onMaterialsRead());
    dispatch(onMaterialsWorkRead());
  }, [isVisibleModalForm]);

  useEffect(() => {
    if (products.length === 0 || setReloadProduct) {
      dispatch(onProductsRead());
      dispatch(onProductValueChange({ setReload: false }));
    }
  }, [isVisibleModalForm]);

  const addMaterialsModal = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo pedido de materiales');
    setModalContent(
      <MaterialForm
        setIsVisibleModalForm={setIsVisibleModalForm}
        provider={true}
      />
    );
  };

  const addMaterialsReceptionModal = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva recepción de materiales');
    setModalContent(
      <MaterialReceptionForm setIsVisibleModalForm={setIsVisibleModalForm} />
    );
  };

  return (
    <div className="materials">
      <div className="materials__header">
        <div className="materials__header-switch">
          <Switch
            defaultChecked
            onChange={() => setViewProviders(!viewProviders)}
          />
          <span>{viewProviders ? 'Proovedores' : 'Obras'}</span>
        </div>
        <div className="materials__header__buttons">
          <Button onClick={addMaterialsReceptionModal}>
            Recepción de Materiales
          </Button>
          <Button onClick={addMaterialsModal}>Compra de Materiales</Button>
        </div>
      </div>
      {viewProviders ? (
        <Tabs>
          {providers.map((provider, index) => (
            <Tabs.TabPane tab={provider.name} key={index}>
              <Collapse defaultActiveKey={1} style={{ marginBottom: '10px' }}>
                <Collapse.Panel header="Recibidos" key={2}>
                  <List
                    className="materials__list"
                    itemLayout="horizontal"
                    dataSource={materialsWork}
                    renderItem={(material) => (
                      <MaterialReception
                        material={material}
                        provider={provider.name}
                      />
                    )}
                  />
                </Collapse.Panel>
                <Collapse.Panel header="Comprados" key={3}>
                  <List
                    className="materials__list"
                    itemLayout="horizontal"
                    dataSource={materials}
                    renderItem={(material) => (
                      <Material material={material} provider={provider.name} />
                    )}
                  />
                </Collapse.Panel>
              </Collapse>
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <Tabs>
          {worksActive.map((work, index) => (
            <Tabs.TabPane tab={work.name} key={index}>
              <Row style={{ marginRight: '73px' }}>
                <Input.Search
                  placeholder="Buscar materiales..."
                  style={{ left: 0, margin: '0 30px 0 0' }}
                  onSearch={(value) => setSearch(value)}
                  allowClear
                />
              </Row>
              <List
                grid={{
                  gutter: 16,
                  xs: 1,
                  sm: 2,
                  md: 4,
                  lg: 4,
                  xl: 6,
                  xxl: 3,
                }}
                dataSource={products}
                renderItem={(product) => (
                  <ProductWork
                    product={product}
                    work={work.name}
                    materialsWork={materialsWork}
                    search={search}
                  />
                )}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
        width="75%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Product(props) {
  const { product, provider, materials, materialsWork, search } = props;

  var quantity = 0;

  if (search) {
    materials.map((material) =>
      material.provider.name === provider
        ? material.materials.map((i) =>
            i.name ==
              `${product.category} - ${product.name} (${product.unit})` &&
            product.category
              .toLowerCase()
              .trim()
              .includes(search.toLowerCase().trim())
              ? (quantity += i.quantity)
              : null
          )
        : null
    );
    if (materialsWork) {
      materialsWork.map((material) =>
        material.provider.name === provider
          ? material.materials.map((i) =>
              i.name ==
                `${product.category} - ${product.name} (${product.unit})` &&
              product.category
                .toLowerCase()
                .trim()
                .includes(search.toLowerCase().trim())
                ? (quantity -= i.quantity)
                : null
            )
          : null
      );
    }
  } else {
    materials.map((material) =>
      material.provider.name === provider
        ? material.materials.map((i) =>
            i.name == `${product.category} - ${product.name} (${product.unit})`
              ? (quantity += i.quantity)
              : null
          )
        : null
    );
    if (materialsWork) {
      materialsWork.map((material) =>
        material.provider.name === provider
          ? material.materials.map((i) =>
              i.name ==
              `${product.category} - ${product.name} (${product.unit})`
                ? (quantity -= i.quantity)
                : null
            )
          : null
      );
    }
  }

  return quantity > 0 ? (
    <div style={{ margin: '10px' }}>
      <Card
        title={product.category}
        style={{ width: '350px', textAlign: 'center' }}
      >
        <Statistic
          style={{ width: '100%' }}
          title={product.name}
          value={quantity}
          valueStyle={
            quantity > 0 ? { color: '#3f8600' } : { color: '#cf1322' }
          }
          suffix={product.unit}
          groupSeparator=""
        />
      </Card>
    </div>
  ) : null;
}

function MaterialReception(props) {
  const { material, provider } = props;
  const day = moment(material.date).format('DD');
  const month = moment(material.date).format('MMMM');
  const year = moment(material.date).format('YYYY');
  if (material.provider.name === provider) {
    return (
      <List.Item
        actions={[
          <Link to={`view-material-reception/${material._id}`}>
            <Button type="primary">
              <InfoCircleOutlined />
            </Button>
            ,
          </Link>,
        ]}
      >
        <div className="materials__list__date">
          <span style={{ marginRight: 2 }}>{day}</span>
          <span style={{ marginRight: 2 }}>{month}</span>
          <span>{year}</span>
        </div>
        <List.Item.Meta
          title={`Recibido en: ${material.work.name}`}
          description={`Nro de remito: ${material.refer}`}
        />
      </List.Item>
    );
  }

  return null;
}

function Material(props) {
  const { material, provider } = props;
  const day = moment(material.date).format('DD');
  const month = moment(material.date).format('MMMM');
  const year = moment(material.date).format('YYYY');

  if (material.provider.name === provider) {
    return (
      <List.Item
        actions={[
          <Link to={`view-material-order/${material._id}`}>
            <Button type="primary">
              <InfoCircleOutlined />
            </Button>
            ,
          </Link>,
        ]}
      >
        <div className="materials__list__date">
          <span style={{ marginRight: 2 }}>{day}</span>
          <span style={{ marginRight: 2 }}>{month}</span>
          <span>{year}</span>
        </div>
        <List.Item.Meta title={`Nro de factura: ${material.invoice}`} />
      </List.Item>
    );
  }

  return null;
}
