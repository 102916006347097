import {
  ON_MOVEMENT_READ,
  ON_MOVEMENT_READING,
  ON_MOVEMENT_CREATE,
  ON_MOVEMENT_FORM_OPEN,
  ON_MOVEMENT_FORM_SUBMIT,
  ON_MOVEMENT_VALUE_CHANGE,
  ON_MOVEMENT_TOOL_VALUE_CHANGE,
} from './types';
import { getMovementsApi } from '../api/movement';
import { getAccessTokenApi } from '../api/auth';
import { notification } from 'antd';

export const onMovementsRead = () => (dispatch) => {
  dispatch({ type: ON_MOVEMENT_READING });
  const token = getAccessTokenApi();

  return getMovementsApi(token).then((response) => {
    dispatch({ type: ON_MOVEMENT_READ, payload: response.movements });
  });
};
