import React, { useState, useEffect } from 'react';
import {
  Input,
  Select,
  Button,
  Row,
  Col,
  notification,
  DatePicker,
  Divider,
  InputNumber,
  Form,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import Modal from '../../Modal';
import {
  onToolValueChange,
  onToolFormOpen,
  onToolCreate,
  onToolUpdate,
  onCategoriesRead,
  onCategoryValueChange,
  onProviderValueChange,
  onProvidersRead,
  onWorksActiveRead,
} from '../../../actions';
import './ToolForm.scss';
import ProviderForm from '../../Provider/ProviderForm';
import ToolCategoryForm from '../../Category/ToolCategoryForm';

export default function ToolForm(props) {
  const { tool, setIsVisibleModal } = props;
  const [workData, setWorkData] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [isVisibleModalForm, setIsVisibleModalForm] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const dispatch = useDispatch();
  const { categories, setReload } = useSelector((store) => store.categoryData);
  const { providers } = useSelector((store) => store.providerData);
  const setReloadProvider = useSelector(
    (store) => store.providerData.setReload
  );
  const tools = useSelector((store) => store.toolList);

  const worksActive = useSelector((store) => store.workList.worksActive);

  useEffect(() => {
    if (tool) {
      dispatch(onToolValueChange(tool));
      dispatch(onToolValueChange({ work: tool.work[0] }));
    } else {
      dispatch(onToolFormOpen());
    }
    dispatch(onWorksActiveRead());
  }, [tool]);

  useEffect(() => {
    if (categories.length === 0 || setReload) {
      dispatch(onCategoriesRead());
      dispatch(onCategoryValueChange({ setReload: false }));
    }
  }, [setReload]);

  useEffect(() => {
    if (providers.length === 0 || setReloadProvider) {
      dispatch(onProvidersRead());
      dispatch(onProviderValueChange({ setReload: false }));
    }
  }, [setReloadProvider]);

  const addCategory = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nueva categoria');
    setModalContent(
      <ToolCategoryForm setIsVisibleModal={setIsVisibleModalForm} />
    );
  };

  const addProvider = () => {
    setIsVisibleModalForm(true);
    setModalTitle('Cargando nuevo proovedor');
    setModalContent(<ProviderForm setIsVisibleModal={setIsVisibleModalForm} />);
  };

  const processTool = (e) => {
    e.preventDefault();

    if (!tool) {
      dispatch(
        onToolCreate(
          {
            date,
            invoice,
            state,
            name,
            mark,
            work,
            description,
            provider,
            quantity,
            refer,
            category,
            dollarPrice,
          },
          setIsVisibleModal
        )
      );
    } else {
      dispatch(
        onToolUpdate(
          tool.id,
          {
            date,
            invoice,
            state,
            name,
            mark,
            work,
            description,
            provider,
            quantity,
            refer,
            category,
            dollarPrice,
          },
          setIsVisibleModal
        )
      );
    }
  };

  const {
    date,
    invoice,
    state,
    name,
    mark,
    description,
    work,
    provider,
    quantity,
    refer,
    category,
    dollarPrice,
    dollarPriceOficial,
  } = useSelector((store) => store.toolForm);

  const { dollarOficial, dollarBlue } = useSelector(
    (store) => store.dollarData
  );

  return (
    <div>
      <Form className="ant-legacy-form" onFinish={processTool}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input
                placeholder="Nombre (*)"
                value={name}
                onChange={(e) =>
                  dispatch(onToolValueChange({ name: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Input
                placeholder="Marca"
                value={mark}
                onChange={(e) =>
                  dispatch(onToolValueChange({ mark: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                placeholder="Seleccione una categoria..."
                value={category}
                onChange={(e) => dispatch(onToolValueChange({ category: e }))}
                showSearch
                optionFilterProp="children"
              >
                <Select.Option disabled>
                  <Button type="link" onClick={addCategory}>
                    + Nueva categoria
                  </Button>
                </Select.Option>
                {categories.map((category, index) => (
                  <Select.Option key={index} value={category.name}>
                    {category.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <InputNumber
                placeholder="Cantidad (*)"
                value={quantity}
                min={0}
                style={{ width: '100%' }}
                onChange={(e) => {
                  console.log(e);
                  dispatch(onToolValueChange({ quantity: e }));
                  dispatch(
                    onToolValueChange({
                      dollarPriceOficial:
                        e * parseInt(dollarOficial) * dollarPrice,
                    })
                  );
                  dispatch(
                    onToolValueChange({
                      totalDollarPrice: e * dollarPrice,
                    })
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <DatePicker
                style={{ width: '100%' }}
                placeholder="Fecha de alta (*)"
                value={date && moment(date)}
                onChange={(value) =>
                  dispatch(
                    onToolValueChange({
                      date: moment(value, 'DD/MM/YYYY').toISOString(),
                    })
                  )
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                placeholder="Seleccione una ubicación.. (*)"
                value={work}
                onChange={(e) => dispatch(onToolValueChange({ work: e }))}
                disabled={tool ? true : false}
                showSearch
                optionFilterProp="children"
              >
                {worksActive.map((work, index) => (
                  <Select.Option
                    key={index}
                    value={tool ? tool.work[0] : work._id}
                  >
                    {tool ? tool.work[1] : work.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                placeholder="Seleccione un estado.. (*)"
                value={state}
                onChange={(e) => dispatch(onToolValueChange({ state: e }))}
                showSearch
                optionFilterProp="children"
              >
                <Select.Option value="Muy Bueno">Muy Bueno</Select.Option>
                <Select.Option value="Bueno">Bueno</Select.Option>
                <Select.Option value="Regular">Regular</Select.Option>
                <Select.Option value="Malo">Malo</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Input.TextArea
                placeholder="Observaciones"
                multiple
                value={description}
                onChange={(e) =>
                  dispatch(onToolValueChange({ description: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider>Datos Compra</Divider>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Input
                placeholder="Nro de factura"
                value={invoice}
                onChange={(e) =>
                  dispatch(onToolValueChange({ invoice: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                placeholder="Nro de remito"
                value={refer}
                onChange={(e) =>
                  dispatch(onToolValueChange({ refer: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Select
                placeholder="Seleccione un proovedor.."
                value={provider}
                onChange={(e) => dispatch(onToolValueChange({ provider: e }))}
                showSearch
                optionFilterProp="children"
              >
                <Select.Option disabled>
                  <Button type="link" onClick={addProvider}>
                    + Nuevo proovedor
                  </Button>
                </Select.Option>
                {providers.map((provider, index) => (
                  <Select.Option key={index} value={provider.name}>
                    {provider.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Input
                prefix={<span>U$</span>}
                placeholder="Precio en dolares por unidad (*)"
                onChange={(e) => {
                  dispatch(onToolValueChange({ dollarPrice: e.target.value }));
                  dispatch(
                    onToolValueChange({
                      dollarPriceOficial:
                        e.target.value * parseInt(dollarOficial) * quantity,
                    })
                  );
                  dispatch(
                    onToolValueChange({
                      totalDollarPrice: e.target.value * quantity,
                    })
                  );
                }}
                value={dollarPrice}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                prefix={<span>U$</span>}
                placeholder=" Precio Total en dolares"
                value={dollarPrice && dollarPrice * quantity}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Input
                prefix={<span>$</span>}
                placeholder="Precio $ (Oficial)"
                value={dollarPrice && dollarPriceOficial}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="button-submit">
            {tool ? 'Actualizar herramienta' : 'Cargar herramienta'}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModalForm}
        setIsVisible={setIsVisibleModalForm}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
