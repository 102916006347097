import React from 'react';
import { Card as CardAntd } from 'antd';
import './Card.scss';

export default function Card({ title, extra, child }) {
  return (
    <CardAntd title={title} extra={extra} className="card">
      {child}
    </CardAntd>
  );
}
