import React from 'react';
import { Form, Input, Row, Col, Button, notification, Select } from 'antd';
import { addProductApi } from '../../../api/configuration';
import { getAccessTokenApi } from '../../../api/auth';
import { onProductValueChange } from '../../../actions';
import { useDispatch } from 'react-redux';

export default function ProductForm(props) {
  const { setIsVisibleModal } = props;
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const onFinish = (values) => {
    const token = getAccessTokenApi();

    addProductApi(token, values)
      .then((response) => {
        if (response.code !== 200) {
          notification['error']({ message: response.message });
        } else {
          notification['success']({ message: response.message });
          form.resetFields();
          dispatch(onProductValueChange({ setReload: true }));
          setIsVisibleModal(false);
        }
      })
      .catch((err) => {
        notification['error']({ message: 'Error del servidor' });
      });
  };
  return (
    <div>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="name"
              initialValue=""
              rules={[{ required: true, message: 'Debe cargar un nombre (*)' }]}
            >
              <Input placeholder="Nombre" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="unit"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar la unidad de medida',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Seleccione una unidad.. (*)"
                optionFilterProp="children"
              >
                <Select.Option value="bs">bs</Select.Option>
                <Select.Option value="ml">ml</Select.Option>
                <Select.Option value="lts">lts</Select.Option>
                <Select.Option value="m2">m2</Select.Option>
                <Select.Option value="m3">m3</Select.Option>
                <Select.Option value="unidades">unidades</Select.Option>
                <Select.Option value="pallet">pallet</Select.Option>
                <Select.Option value="barras">barras</Select.Option>
                <Select.Option value="Kg">Kg</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="category"
              rules={[
                {
                  required: true,
                  message: 'Debe cargar la caegoria',
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: '100%' }}
                placeholder="Seleccione una categoria.. (*)"
                optionFilterProp="children"
              >
                <Select.Option value="Obra gruesa">Obra Gruesa</Select.Option>
                <Select.Option value="Ladrillos">Ladrillos</Select.Option>
                <Select.Option value="Hormigon">Hormigon</Select.Option>
                <Select.Option value="Hierro">Hierro</Select.Option>
                <Select.Option value="Electricidad">Electricidad</Select.Option>
                <Select.Option value="Plomeria">Plomeria</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item>
              <Button
                style={{ width: '100%' }}
                type="primary"
                htmlType="submit"
                className="button-submit"
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
