import { useSelector } from 'react-redux';

export default function PermissionsAsigner(props) {
  const { requiredRole, visible, type, children } = props;
  const roleSession = useSelector((store) => store.userData.user.role);
  const typeSession = useSelector((store) => store.userData.user.type);

  return (requiredRole && roleSession >= requiredRole.value) ||
    (visible && visible.value === roleSession) ||
    (type && type === typeSession)
    ? children
    : null;
}
