import React from 'react';
import { Button, InputNumber, Table, Tooltip, message } from 'antd';
import {
  CheckCircleOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { addRecivedOrderApi } from '../../api/order';
import { getAccessTokenApi } from '../../api/auth';
export default function MaterialTable({
  items,
  adminItem,
  receptionItem,
  changeStatus,
  closedOrder,
}) {
  const columns = [
    {
      title: 'Material',
      width: 50,
      dataIndex: 'material',
      key: 'material',
      render: (material) => (
        <span>
          {material.category} - {material.name} ({material.unit})
        </span>
      ),
      sorter: (a, b) => a.material.name.localeCompare(b.material.name),
    },
    receptionItem
      ? {
          title: 'Cantidad enviada',
          width: 50,
          dataIndex: 'material',
          key: 'send',
          render: (material) => <span>{material.send.quantity}</span>,
          sorter: (a, b) => a.material.send.quantity - b.material.send.quantity,
        }
      : {
          title: adminItem || closedOrder ? 'Cantidad solicitada' : 'Cantidad',
          width: 50,
          dataIndex: 'material',
          key: 'quantity',
          render: (material) => <span>{material.quantity}</span>,
          sorter: (a, b) => a.material.quantity - b.material.quantity,
        },
  ];

  closedOrder &&
    columns.push(
      {
        title: 'Cantidad aprobada',
        width: 50,
        dataIndex: 'material',
        key: 'send',
        render: (material) => {
          return (
            <div>
              <span style={{ padding: '5px' }}>{material.send.quantity}</span>
              {material.quantity === material.send.quantity ? (
                <Tooltip title="Encargado igual a lo pedido">
                  <CheckCircleOutlined style={{ color: '#339900' }} />
                </Tooltip>
              ) : material.send.quantity === 0 ? (
                <Tooltip title="No se encargo el material">
                  <CloseCircleOutlined style={{ color: '#cc3300' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Encargado menos o mas que lo pedido">
                  <ExclamationCircleOutlined style={{ color: '#ff9966' }} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: 'Cantidad recibida',
        width: 50,
        dataIndex: 'material',
        key: 'reception',
        render: (material) => <span>{material.send.quantityRecived}</span>,

        render: (material) => {
          return (
            <div>
              <span style={{ padding: '5px' }}>
                {material.send.quantityRecived}
              </span>
              {material.send.quantityRecived === material.send.quantity ? (
                <Tooltip title="Recibido igual a lo encargado">
                  <CheckCircleOutlined style={{ color: '#339900' }} />
                </Tooltip>
              ) : material.send.quantityRecived < material.send.quantity ? (
                <Tooltip title="Recibido menor a lo encargado">
                  <ExclamationCircleOutlined style={{ color: '#cc3300' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Recibido mayor a lo encargado">
                  <ExclamationCircleOutlined style={{ color: '#ff9966' }} />
                </Tooltip>
              )}
            </div>
          );
        },
        sorter: (a, b) =>
          a.material.send.quantityRecived - b.material.send.quantityRecived,
      },
      {
        title: 'Proveedor',
        width: 50,
        dataIndex: 'material',
        key: 'provider',
        render: (material) => <span>{material?.provider?.name}</span>,
      }
    );

  adminItem &&
    columns.push(
      {
        title: 'Observación',
        width: 50,
        dataIndex: 'material',
        key: 'observation',
        render: (material) => <span>{material?.observation}</span>,
      },
      {
        title: 'Cantidad aprobada',
        width: 50,
        dataIndex: 'material',
        key: 'send',
        render: (material) => {
          let count = material?.send
            .map((item) => item.quantity)
            .reduce((count, item) => count + parseFloat(item), 0);
          return (
            <div>
              <span style={{ padding: '5px' }}>{count}</span>
              {material.quantity === count ? (
                <Tooltip title="Encargado igual a lo pedido">
                  <CheckCircleOutlined style={{ color: '#339900' }} />
                </Tooltip>
              ) : count === 0 ? (
                <Tooltip title="No se encargo el material">
                  <CloseCircleOutlined style={{ color: '#cc3300' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Encargado menos o mas que lo pedido">
                  <ExclamationCircleOutlined style={{ color: '#ff9966' }} />
                </Tooltip>
              )}
            </div>
          );
        },
      },
      {
        title: 'Gestionar pedido',
        width: 50,
        dataIndex: '',
        key: '',
        render: (material) => (
          <div>
            <Tooltip
              title={
                items[0]?.status !== 'En curso'
                  ? 'Debe aceptar el pedido para poder gestionarlo'
                  : 'Gestionar item'
              }
            >
              <Button type="link" disabled={items[0]?.status !== 'En curso'}>
                <SettingOutlined onClick={() => adminItem(material)} />
              </Button>
            </Tooltip>
          </div>
        ),
      }
    );

  receptionItem &&
    columns.push(
      {
        title: 'Proveedor',
        width: 50,
        dataIndex: 'material',
        key: 'provider',
        render: (material) => <span>{material?.provider.name}</span>,
      },
      {
        title: 'Cantidad recibida',
        width: 50,
        dataIndex: 'material',
        key: 'reception',
        render: (material) => {
          let value = material.send.quantity;
          return (
            <div>
              <Tooltip title="Completar con la cantidad recibida">
                <InputNumber
                  style={{ marginRight: '5px' }}
                  defaultValue={
                    material.send?.quantityRecived || material.send.quantity
                  }
                  onChange={(x) => (value = x)}
                  disabled={material.send?.quantityRecived}
                />
              </Tooltip>
              {!material.send?.quantityRecived ? (
                <Tooltip title="Marcar como recibido">
                  <CheckOutlined
                    style={{ color: 'green' }}
                    onClick={async () => {
                      let token = await getAccessTokenApi();
                      await addRecivedOrderApi({
                        token,
                        order: items[0]._id,
                        itemId: material.itemId,
                        sendItemId: material.send._id,
                        material: material._id,
                        recivedData: { quantityRecived: value },
                      })
                        .then((response) => {
                          if (response?.code !== 200) {
                            message.error(response.message);
                          } else {
                            message.success(response.message);
                            changeStatus('Recibido parcial', 'send');
                          }
                        })
                        .catch(() => {
                          message.error(
                            'Error del servidor, intente mas tarde.'
                          );
                        });
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip title="Editar">
                  <EditOutlined
                    // style={{ color: '' }}
                    onClick={async () => {
                      let token = await getAccessTokenApi();
                      await addRecivedOrderApi({
                        token,
                        order: items[0]._id,
                        itemId: material.itemId,
                        sendItemId: material.send._id,
                        material: material._id,
                        recivedData: {
                          quantityRecived: null,
                          edit: material.send?.quantity,
                        },
                      })
                        .then((response) => {
                          if (response?.code !== 200) {
                            message.error(response.message);
                          } else {
                            message.success(response.message);
                            changeStatus('Recibido parcial', 'send');
                          }
                        })
                        .catch(() => {
                          message.error(
                            'Error del servidor, intente mas tarde.'
                          );
                        });
                    }}
                  />
                </Tooltip>
              )}
            </div>
          );
        },
      }
    );

  return (
    <Table
      columns={columns}
      dataSource={items}
      scroll={{ x: 500 }}
      pagination={false}
      locale={{ emptyText: 'No se encontraron datos' }}
    />
  );
}
