import {
  ON_MATERIAL_WORK_READ,
  ON_MATERIAL_WORK_READING,
  ON_MATERIAL_WORK_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = { materialsWork: [], loading: false };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_MATERIAL_WORK_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_MATERIAL_WORK_READING:
      return { ...state, loading: true };
    case ON_MATERIAL_WORK_READ:
      return { ...state, materials: action.payload, loading: false };

    default:
      return state;
  }
};
