import {
  ON_WORK_READ,
  ON_WORK_READING,
  ON_WORK_ACTIVE_READING,
  ON_WORK_ACTIVE_READ,
  ON_WORK_VALUE_CHANGE,
} from '../actions/types';

const INITIAL_STATE = {
  works: [],
  worksActive: [],
  loading: false,
  setReload: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_WORK_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_WORK_READING:
      return { ...state, loading: true };
    case ON_WORK_READ:
      return { ...state, works: action.payload, loading: false };
    case ON_WORK_ACTIVE_READING:
      return { ...state, loading: true };
    case ON_WORK_ACTIVE_READ:
      return { ...state, worksActive: action.payload, loading: false };
    default:
      return state;
  }
};
