import React from 'react';
import {
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  SyncOutlined,
} from '@ant-design/icons';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';

export const ROLES = {
  OWNER: { value: 2, name: 'Acceso completo', roleId: 'Medico' },
  ADMIN: { value: 1, name: 'Medico Completo', roleId: 'Medico' },
  SECRETARY: {
    value: 0,
    name: 'Administrativo',
    roleId: 'Administrativo',
  },
  ADMININFARMARY: {
    value: -1,
    name: 'Admin. Enfermería',
    roleId: 'Enfermero',
  },
  INFARMARY: { value: -2, name: 'Enfermería', roleId: 'Enfermero' },
  VIEW: { value: -3, name: 'Medico Ambulatorio', roleId: 'Medico' },
  EMPLOYEE: {
    value: -4,
    name: 'Interconsulta',
    roleId: 'Medico',
  },
};

export const units = [
  { name: 'mg' },
  { name: 'gr' },
  { name: 'ml' },
  { name: 'mug' },
  { name: 'unidad' },
  { name: 'puff' },
  { name: 'ampolla' },
  { name: 'gotas' },
  { name: 'comprimido' },
  { name: 'frasco' },
];

export const priorities = [
  { name: 'Alta', color: 'red' },
  { name: 'Media', color: '#ffcc00' },
  { name: 'Baja', color: 'green' },
];

export const statusCustomize = {
  Pendiente: { color: 'warning', icon: <ClockCircleOutlined /> },
  'En curso': { color: 'blue', icon: <SyncOutlined /> },
  Aprobado: { color: 'success', icon: <CheckOutlined /> },
  Recibido: { color: 'success', icon: <CheckOutlined /> },
  Rechazado: { color: 'error', icon: <CloseOutlined /> },
  'Pendiente envio': { color: 'volcano', icon: <CarOutlined /> },
  'Recibido parcial': { color: 'volcano', icon: <SyncOutlined /> },
  'Recibido parcial - Cerrado': { color: 'warning', icon: <CheckOutlined /> },
};

export const incomesInfarmary = [
  { name: 'D/A 5%' },
  { name: 'D/A 10%' },
  { name: 'D/A 25%' },
  { name: 'D/A 50%' },
  { name: 'SEB' },
  { name: 'S/F 0,9%' },
  { name: 'MANITOL' },
  { name: 'RINGER L.' },
  { name: 'SANGRE' },
  { name: 'PLASMA' },
  { name: 'OTROS' },
  { name: 'ORAL' },
  { name: 'SNG/SNY' },
  { name: 'YEYUNOSTOM' },
];

export const expensesInfarmary = [
  { name: 'DIURESIS' },
  { name: 'DEPOSICION' },
  { name: 'VOMITO-SNG' },
  { name: 'DRENAJE A' },
  { name: 'DRENAJE B' },
  { name: 'DRENAJE C' },
  { name: 'OTROS' },
];

export const MONTHS = [
  ['Enero', 0],
  ['Febrero', 0],
  ['Marzo', 0],
  ['Abril', 0],
  ['Mayo', 0],
  ['Junio', 0],
  ['Julio', 0],
  ['Agosto', 0],
  ['Septiembre', 0],
  ['Octubre', 0],
  ['Noviembre', 0],
  ['Diciembre', 0],
];

export const days = [
  { name: 'Lunes' },
  { name: 'Martes' },
  { name: 'Miercoles' },
  { name: 'Jueves' },
  { name: 'Viernes' },
  { name: 'Sabado' },
  { name: 'Domingo' },
];

export const rangeAges = [
  { start: 0, end: 9 },
  { start: 10, end: 20 },
  { start: 21, end: 30 },
  { start: 31, end: 40 },
  { start: 41, end: 50 },
  { start: 51, end: 60 },
  { start: 61, end: 70 },
  { start: 71, end: 80 },
  { start: 81, end: 90 },
  { start: 91, end: 100 },
  { start: 100, end: 110 },
];
