import React, { useState, useEffect } from 'react';
import { List, Descriptions, Button, notification, Switch } from 'antd';
import Modal from '../../Modal';
import './ListWork.scss';
import FormWork from '../FormWork';
import { useDispatch, useSelector } from 'react-redux';
import { getAccessTokenApi } from '../../../api/auth';
import { activateWorkrApi } from '../../../api/work';
import { Link } from 'react-router-dom';
import {
  CheckOutlined,
  EditOutlined,
  InfoCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';

export default function ListWork(props) {
  const { setReload, active } = props;
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const { works, loading } = useSelector((store) => store.workList);

  const editWorkModal = (work) => {
    setIsVisibleModal(true);
    setModalTitle('Actualizando obra');
    setModalContent(
      <FormWork
        setIsVisibleModal={setIsVisibleModal}
        work={work}
        setReload={setReload}
      />
    );
  };

  const changeStateWork = (work) => {
    const token = getAccessTokenApi();

    activateWorkrApi(token, work._id, !active)
      .then((result) => {
        notification['success']({ message: result });
        setReload(true);
      })
      .catch((err) => {
        notification['error']({ message: err });
      });
  };

  return (
    <div className="works">
      <div className="works__list">
        <List
          itemLayout="horizontal"
          dataSource={works}
          renderItem={(work) => (
            <Work
              work={work}
              changeStateWork={changeStateWork}
              active={active}
              editWorkModal={editWorkModal}
            />
          )}
          loading={loading}
        />
      </div>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width="75%"
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Work(props) {
  const { work, changeStateWork, active, editWorkModal } = props;

  return (
    <List.Item
      actions={
        active
          ? [
              <Link to={`view-work/${work._id}`}>
                <Button type="link">
                  <InfoCircleOutlined />
                </Button>
              </Link>,
              <Button type="link" onClick={() => editWorkModal(work)}>
                <EditOutlined />
              </Button>,
              <Button
                type="link"
                style={{ color: 'red' }}
                onClick={() => changeStateWork(work)}
              >
                <StopOutlined />
              </Button>,
            ]
          : [
              <Link to={`view-work/${work._id}`}>
                <Button type="link">
                  <InfoCircleOutlined />
                </Button>
              </Link>,
              <Button type="link" onClick={() => editWorkModal(work)}>
                <EditOutlined />
              </Button>,
              <Button
                style={{ color: '#4BB543' }}
                type="link"
                onClick={() => changeStateWork(work)}
              >
                <CheckOutlined />
              </Button>,
            ]
      }
    >
      <div>
        <Descriptions title={work?.name} column={1}>
          <Descriptions.Item label="Dirección">
            {work?.direction}
          </Descriptions.Item>
          <Descriptions.Item label="Comitente">
            {work?.client && `${work?.client.name} - ${work?.client.type}`}
          </Descriptions.Item>
        </Descriptions>
      </div>
      <List.Item.Meta />
    </List.Item>
  );
}
