import {
  ON_TOOL_VALUE_CHANGE,
  ON_TOOL_FORM_OPEN,
  ON_TOOL_FORM_SUBMIT,
  ON_TOOL_CREATE,
} from '../actions/types';

const INITIAL_STATE = {
  name: '',
  mark: '',
  date: '',
  work: '',
  description: '',
  quantity: '',
  state: '',
  provider: '',
  invoice: '',
  category: '',
  refer: '',
  dollarPrice: '',
  totalDollarPrice: '',
  dollarPriceOficial: '',
  dollarPriceBlue: '',
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ON_TOOL_VALUE_CHANGE:
      return { ...state, ...action.payload };
    case ON_TOOL_FORM_OPEN:
      return { INITIAL_STATE };
    case ON_TOOL_FORM_SUBMIT:
      return { ...state, loading: true };
    case ON_TOOL_CREATE:
      return { INITIAL_STATE, loading: false };
    default:
      return state;
  }
};
