import { Collapse, Tooltip, Button, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { getProtocoloApi } from '../../api/protocolo';
import { getAccessTokenApi } from '../../api/auth';
import { getPdfApi } from '../../api/cloudStorage';
import PdfViewer from '../../components/PdfViewer/PdfViewer';
import Spin from '../../components/Spin';
import { EyeOutlined } from '@ant-design/icons';

export default function Protocolo() {
  const [protocolos, setProtocolos] = useState([]);
  const [pdf, setPdf] = useState('');
  const [pdfName, setPdfName] = useState();
  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    const getData = async () => {
      let token = await getAccessTokenApi();

      getProtocoloApi(token)
        .then((response) => setProtocolos(response.protocolos))
        .catch((err) => {
          notification['error']({ message: 'Error del servidor' });
        });
    };
    getData();
  }, []);

  const viewPdf = async (pdfName) => {
    let token = await getAccessTokenApi();
    setLoadingPdf(true);

    await getPdfApi(token, 'pdfs', pdfName)
      .then((response) => {
        setPdf(response.url);
        setPdfName(pdfName);
        setLoadingPdf(false);
      })
      .catch((err) => notification['error']({ message: 'Error del servidor' }));
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Protocolos</h1>
      {protocolos?.map((protocolo) => {
        return (
          <Collapse
            collapsible=""
            onChange={(e) => e.length !== 0 && viewPdf(protocolo.pdfName)}
          >
            <Collapse.Panel
              header={protocolo.name}
              extra={
                <Tooltip title="Ver en pantalla completa">
                  <a href={pdf} target="_blank" referrerPolicy="origin">
                    <Button
                      type="link"
                      size="small"
                      disabled={pdfName !== protocolo.pdfName}
                    >
                      <EyeOutlined />
                    </Button>
                  </a>
                </Tooltip>
              }
            >
              {loadingPdf ? (
                <Spin />
              ) : (
                <div style={{ textAlign: 'center', height: '100%' }}>
                  <PdfViewer pdf={pdf} />
                </div>
              )}
            </Collapse.Panel>
          </Collapse>
        );
      })}
    </div>
  );
}
