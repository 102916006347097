import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { List, Button, Collapse, Modal as ModalAntd, Input } from 'antd';
import Modal from '../Modal';
import ProviderForm from '../Provider/ProviderForm';
import ProductForm from '../Materials/ProductForm';
import {
  onProductValueChange,
  onProductsRead,
  onProviderDelete,
  onProvidersRead,
  onProviderValueChange,
  onProductDelete,
  onCategoryDelete,
  onCategoryValueChange,
  onCategoriesRead,
  onClientsRead,
  onClientValueChange,
  onClientDelete,
} from '../../actions';

import './ListConfigurations.scss';
import ToolCategoryForm from '../Category/ToolCategoryForm';
import FormClient from '../Client/FormClient';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

export default function ListConfigurations() {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [search, setSearch] = useState('');

  const { products } = useSelector((store) => store.productData);
  const setReloadProduct = useSelector((store) => store.productData.setReload);
  const { providers } = useSelector((store) => store.providerData);
  const setReloadProvider = useSelector(
    (store) => store.providerData.setReload
  );
  const { categories } = useSelector((store) => store.categoryData);
  const setReloadCategory = useSelector(
    (store) => store.categoryData.setReload
  );
  const { clients } = useSelector((store) => store.clientData);
  const setReloadClient = useSelector((store) => store.clientData.setReload);

  const dispatch = useDispatch();

  useEffect(() => {
    if (products.length === 0 || setReloadProduct) {
      dispatch(onProductsRead());
      dispatch(onProductValueChange({ setReload: false }));
    }
  }, [setReloadProduct]);

  useEffect(() => {
    if (providers.length === 0 || setReloadProvider) {
      dispatch(onProvidersRead());
      dispatch(onProviderValueChange({ setReload: false }));
    }
  }, [setReloadProvider]);

  useEffect(() => {
    if (categories.length === 0 || setReloadCategory) {
      dispatch(onCategoriesRead());
      dispatch(onCategoryValueChange({ setReload: false }));
    }
  }, [setReloadCategory]);

  useEffect(() => {
    if (clients.length === 0 || setReloadClient) {
      dispatch(onClientsRead());
      dispatch(onClientValueChange({ setReload: false }));
    }
  }, [setReloadClient]);

  const addProvider = (e) => {
    e.stopPropagation();
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo proovedor');
    setModalContent(<ProviderForm setIsVisibleModal={setIsVisibleModal} />);
  };

  const addProduct = (e) => {
    e.stopPropagation();
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo producto');
    setModalContent(<ProductForm setIsVisibleModal={setIsVisibleModal} />);
  };

  const addCategory = (e) => {
    e.stopPropagation();
    setIsVisibleModal(true);
    setModalTitle('Cargando nueva categoria');
    setModalContent(<ToolCategoryForm setIsVisibleModal={setIsVisibleModal} />);
  };

  const addClient = (e) => {
    e.stopPropagation();
    setIsVisibleModal(true);
    setModalTitle('Cargando nuevo comitente/cliente');
    setModalContent(<FormClient setIsVisibleModal={setIsVisibleModal} />);
  };

  const editClient = (client) => {
    setIsVisibleModal(true);
    setModalTitle('Editando comitente/cliente');
    setModalContent(
      <FormClient client={client} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const editProvider = (provider) => {
    setIsVisibleModal(true);
    setModalTitle('Editando proovedor');
    setModalContent(
      <ProviderForm provider={provider} setIsVisibleModal={setIsVisibleModal} />
    );
  };

  const showDeleteConfirmProvider = (provider) => {
    const id = provider._id;
    ModalAntd.confirm({
      title: 'Eliminando proovedor',
      content: (
        <span>
          Estas seguro que quieres eliminar {provider.name}?
          <br />
          <span style={{ color: 'red' }}>
            ATENCION!
            <br />
            Antes de eliminar, asegurarse que el proovedor no tenga materiales
            asociados.
          </span>
        </span>
      ),
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onProviderDelete(id));
      },
    });
  };

  const showDeleteConfirmProduct = (product) => {
    const id = product._id;
    ModalAntd.confirm({
      title: 'Eliminando producto',
      content: (
        <span>
          Estas seguro que quieres eliminar {product.category} - {product.name}?
          <br />
          <span style={{ color: 'red' }}>
            ATENCION!
            <br />
            Antes de eliminar, asegurarse que el producto no este pendiente de
            enetrega por ningun proovedor.
          </span>
        </span>
      ),
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onProductDelete(id));
      },
    });
  };

  const showDeleteConfirmCategoy = (category) => {
    const id = category._id;
    ModalAntd.confirm({
      title: 'Eliminando categoria',
      content: <span>Estas seguro que quieres eliminar {category.name}?</span>,
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onCategoryDelete(id));
      },
    });
  };

  const showDeleteConfirmClient = (client) => {
    const id = client._id;
    ModalAntd.confirm({
      title: 'Eliminando comitente/cliente',
      content: <span>Estas seguro que quieres eliminar {client.name}?</span>,
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk() {
        dispatch(onClientDelete(id));
      },
    });
  };

  const productsFilters = products.filter(
    (product) =>
      product.name.toLowerCase().trim().includes(search.toLowerCase().trim()) ||
      product.category
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase().trim()) ||
      product.unit
        .toLowerCase()
        .trim()
        .includes(search.toLocaleLowerCase().trim())
  );

  return (
    <div>
      <Collapse>
        <Collapse.Panel
          key={1}
          header="Proovedores"
          extra={
            <Button
              type="link"
              shape="circle"
              onClick={(e) => addProvider(e)}
              size="small"
            >
              <PlusOutlined />
            </Button>
          }
        >
          <div style={{ textAlign: 'right' }}></div>
          <List
            className="configurattions__list"
            itemLayout="horizontal"
            dataSource={providers}
            renderItem={(provider) => (
              <Provider
                provider={provider}
                showDeleteConfirmProvider={showDeleteConfirmProvider}
                editProvider={editProvider}
              />
            )}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={2}
          header="Productos"
          extra={
            <Button
              type="link"
              shape="circle"
              onClick={(e) => addProduct(e)}
              size="small"
            >
              <PlusOutlined />
            </Button>
          }
        >
          <Input.Search
            placeholder="Buscar productos..."
            style={{ left: 0, margin: '0 10px 0 0' }}
            onSearch={(value) => setSearch(value)}
            allowClear
          />
          <List
            className="configurattions__list"
            itemLayout="horizontal"
            dataSource={search ? productsFilters : products}
            renderItem={(product) => (
              <Product
                product={product}
                showDeleteConfirmProduct={showDeleteConfirmProduct}
              />
            )}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={3}
          header="Categorias de herramientas"
          extra={
            <Button
              type="link"
              shape="circle"
              onClick={(e) => addCategory(e)}
              size="small"
            >
              <PlusOutlined />
            </Button>
          }
        >
          <List
            className="configurattions__list"
            itemLayout="horizontal"
            dataSource={categories}
            renderItem={(category) => (
              <ToolCategory
                category={category}
                showDeleteConfirmCategoy={showDeleteConfirmCategoy}
              />
            )}
          />
        </Collapse.Panel>
        <Collapse.Panel
          key={4}
          header="Comitentes/Clientes"
          extra={
            <Button
              type="link"
              shape="circle"
              onClick={(e) => addClient(e)}
              size="small"
            >
              <PlusOutlined />
            </Button>
          }
        >
          <List
            className="configurattions__list"
            itemLayout="horizontal"
            dataSource={clients}
            renderItem={(client) => (
              <Client
                client={client}
                editClient={editClient}
                showDeleteConfirmClient={showDeleteConfirmClient}
              />
            )}
          />
        </Collapse.Panel>
      </Collapse>

      <Modal
        title={modalTitle}
        isVisible={isVisibleModal}
        setIsVisible={setIsVisibleModal}
        width={modalTitle === 'Cargando nueva categoria' ? '50%' : '75%'}
      >
        {modalContent}
      </Modal>
    </div>
  );
}

function Provider(props) {
  const { provider, showDeleteConfirmProvider, editProvider } = props;
  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={() => editProvider(provider)}>
          <EditOutlined />
        </Button>,
        <Button
          type="danger"
          onClick={() => showDeleteConfirmProvider(provider)}
        >
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={
          !provider.direction
            ? ` ${provider.name}`
            : `${provider.name} - Dirección: ${provider.direction}`
        }
        description={
          !provider.phone && !provider.mail
            ? null
            : `Teléfono: ${provider.phone} - e-mail: ${provider.mail}`
        }
      />
    </List.Item>
  );
}

function Product(props) {
  const { product, showDeleteConfirmProduct } = props;
  return (
    <List.Item
      actions={[
        <Button type="danger" onClick={() => showDeleteConfirmProduct(product)}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={`${product.name} (${product.unit})`}
        description={`${product.category}`}
      />
    </List.Item>
  );
}

function ToolCategory(props) {
  const { category, showDeleteConfirmCategoy } = props;
  return (
    <List.Item
      actions={[
        <Button
          type="danger"
          onClick={() => showDeleteConfirmCategoy(category)}
        >
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta title={<span>{category.name}</span>} />
    </List.Item>
  );
}

function Client(props) {
  const { client, showDeleteConfirmClient, editClient } = props;
  return (
    <List.Item
      actions={[
        <Button type="primary" onClick={() => editClient(client)}>
          <EditOutlined />
        </Button>,
        <Button type="danger" onClick={() => showDeleteConfirmClient(client)}>
          <DeleteOutlined />
        </Button>,
      ]}
    >
      <List.Item.Meta
        title={`${client.name} - ${client.type}`}
        description={client.mail && client.mail}
      />
    </List.Item>
  );
}
